import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function CookieBar() {
  //return <></>;
  return (
    <CookieConsent
      debug={false}
      buttonText="Aceitar e fechar!"
      buttonStyle={{ backgroundColor: "var(--primary2)", color: "white" }}
      //enableDeclineButton
      //declineButtonText="Eu recuso"
      //overlay
    >
      Nós usamos cookies e tecnologias semelhantes para garantir que você
      obtenha a melhor experiência em nosso site. Ao usar nosso site você
      concorda com o uso dos cookies e destas tecnologias.{" "}
      <Link to={`/privacidade`}>Saber mais</Link>
    </CookieConsent>
  );
}
export default CookieBar;
