import React, { Component } from 'react'
import { Col, Row, Skeleton, Empty, Collapse } from 'antd';
import style from './style.module.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMaterias } from '../../actions/Materias.action'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import BarraHistorico from '../../components/BarraHistorico'
import RenderHelmet from '../../components/RenderHelmet'
import { withRouter } from "react-router"

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

const { Panel } = Collapse;

class Disciplinas extends Component {

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/disciplinas");
    window.scrollTo(0, 0)
    this.props.getMaterias()
  }
  

  renderLoading = () => {
    return (
      <>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
      </>
    )
  }

  renderDisciplina = (disciplina) => {
    var total =  0;
    return (
      <Col key={disciplina.id} style={{marginBottom: 48, width: '100%'}} sm={{ span: 24 }} md={{ span: 12 }}>
        <div className={style.boxDisciplina}>
          <h3 style={{ lineHeight: '1em' }}>{disciplina.nome}</h3>

          <Collapse bordered={false} className={style.collapse}>
            <Panel className={style.panel} header={<p className={style.panelHeader}>Assuntos:</p>}>
              {
                disciplina.assuntos.map(assunto => {
                  total += assunto.questoes.length
                  return <p key={assunto.id} className={style.collapseContent}><Link to={`/questoes?assuntos[]=${assunto.id}`}><LinkOutlined /> {assunto.nome}</Link></p>
                })
              }
            </Panel>
          </Collapse>

          <p className="no-margin">
            <span className="strong no-margin">Total de: </span>
            <Link to={`/questoes?materias[]=${disciplina.id}`}><LinkOutlined /> {total} questões</Link>
          </p>

        </div>
      </Col>
    )
  }

  renderDisplinas = (disciplinas) => {
    return disciplinas.map(disciplina => {
      return this.renderDisciplina(disciplina)
    })
  }

  render() {
    const { loadMaterias, materias } = this.props.materias
    return (
      <div className="container">
        <RenderHelmet title={'Cade Direito: Disciplinas'} description={'Lista de disciplinas'}/>
        <BarraHistorico title={'Disciplinas'} match={ this.props.match }/>
        <Row gutter={[24,24]}>
          {
            materias
              ? this.renderDisplinas(materias)
              : (
                !loadMaterias
                  ? <Empty description="Não há disciplinas disponíveis" style={{ margin: '64px 0 128px 0' }} />
                  : <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
              )
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    materias: state.materias
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMaterias }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Disciplinas))