import React, { Component } from "react";
import styles from "./style.module.css";
import { Dropdown, Avatar } from "antd";
import Modal from "../Modal";
import { Link } from "react-router-dom";
//import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

class Authenticate extends Component {
  DropdownLogin = (
    <Modal>
      <Modal.Login />
    </Modal>
  );

  DropdownUser = (
    <Modal>
      <Modal.User />
    </Modal>
  );

  Authenticated = () => {
    const { nome } = this.props.login.usuario;
    return (
      <Dropdown overlay={this.DropdownUser} placement="bottomCenter">
        <li>
          {nome}
          <Avatar size={28} style={{ marginLeft: 8 }} icon={<UserOutlined />} />
        </li>
      </Dropdown>
    );
  };

  Unauthenticated = () => {
    return (
      <>
        <li>
          <Link style={{ color: "inherit" }} to={`/registrar`}>
            Registrar
          </Link>
        </li>
        <Dropdown overlay={this.DropdownLogin} placement="bottomCenter">
          <li>Login</li>
        </Dropdown>
      </>
    );
  };

  render() {
    const { isAuthenticate } = this.props.login;
    return (
      <ul className={styles.menu}>
        {isAuthenticate ? this.Authenticated() : this.Unauthenticated()}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

/*const mapDispatchToProps = dispatch =>
  bindActionCreators({  }, dispatch)*/

export default connect(mapStateToProps /*, mapDispatchToProps*/)(Authenticate);
