import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import style from './style.module.css'

export default props => {

  var [show, setShow] = useState(false)

  const { children = 'termos de uso' } = props || {}

  return (
    <>
      <Button style={{padding: 0}} type="link" onClick={() => setShow(true)}>{children}</Button>
      <Modal
        visible={show}
        closable={false}
        className={style.modalTermos}
        bodyStyle={{ height: 400, overflow: 'scroll'}}
        footer={[
          <Button key="submit" onClick={() => setShow(false)}>
            Fechar
          </Button>,
        ]}
      >
        <h3>1. TERMOS</h3>
        
        <p>Ao acessar ao site <a href='https://www.cadedireito.com.br'>Cadê Direito</a>, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>

        <h3>2. USO DE LICENÇA</h3>

<p>É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Cadê Direito, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: </p>
<ol>
<li>modificar ou copiar os materiais;</li>
<li>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
<li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Cadê Direito;</li>
<li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou  </li>
<li>transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.</li>
</ol>

<p>Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Cadê Direito a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.</p>

<h3>3. ISENÇÃO DE RESPONSABILIDADE</h3>

<ol>
<li>Os materiais no site da Cadê Direito são fornecidos 'como estão'. Cadê Direito não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.</li>
<li>Além disso, o Cadê Direito não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.</li>
</ol>

<h3>4. LIMITAÇÕES</h3>

<p>Em nenhum caso o Cadê Direito ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Cadê Direito, mesmo que Cadê Direito ou um representante autorizado da Cadê Direito tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.</p>

<h3>5. PRECISÃO DE MATERIAIS</h3>

<p>Os materiais exibidos no site da Cadê Direito podem incluir erros técnicos, tipográficos ou fotográficos. Cadê Direito não garante que qualquer material em seu site seja preciso, completo ou atual. Cadê Direito pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, Cadê Direito não se compromete a atualizar os materiais.</p>

<h3>6. LINKS</h3>

<p>O Cadê Direito  não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por Cadê Direito do site. O uso de qualquer site vinculado é por conta e risco do usuário.</p>

<h3>7. MODIFICAÇÕES</h3>

<p>O Cadê Direito pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.</p>

<h3>8. SOBRE ESTES TERMOS</h3>
<p>Para se adaptar às expectativas dos usuários, o CADÊ DIREITO poderá modificar este Termo de Uso. Assim, é importante que você o consulte regularmente e caso não concorde com as alterações promovidas, entre em contato com nossos canais de relacionamento ou descontinue o uso do seu serviço.</p>

<h3>9. DURAÇÃO DA PRESTAÇÃO DE SERVIÇOS</h3>
<p>O Portal Cadê Direito e os demais Serviços tem duração indeterminada, estando facultado ao CADÊ DIREITO extingui-los, suspendê-los ou interrompê-los a qualquer momento.</p>

<h3>10. LEI APLICÁVEL E ELEIÇÃO DE FORO</h3>
<p>Todos os itens deste Termo de Uso são regidos pelas leis vigentes no ordenamento jurídico brasileiro. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a este Termo de Uso, as partes concordam em se submeter ao Foro da Comarca do Rio de Janeiro, com exceção de reclamações apresentadas por usuários que se enquadrem no conceito legal de consumidores, que poderão submeter tais reclamações ao foro de seu domicílio.</p>

<p>Para qualquer sugestão ou proposta de colaboração, escreva ao endereço contato@cadedireito.com.br</p>
      </Modal>
    </>
  )
}