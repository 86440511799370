import React from "react";
import { Modal, Row, Col } from "antd";
import style from "./style.module.css";

export default function ModalGabarito({ onOk, onCancel, gabarito, simulado }) {
  const matriz = gabarito ? JSON.parse(gabarito.matriz) : null;

  var matrizCorreta = {};
  if (simulado) {
    simulado.simulado_questoes.map((questao, key) => {
      return (matrizCorreta[key + 1] = questao.opcaoCorreta);
    });
  }

  return (
    <Modal
      title={`Gabarito`}
      visible={"true"}
      onOk={onOk}
      className={style.modal}
      onCancel={onCancel}
      footer={[]}
    >
      {matriz ? (
        <>
          {Object.keys(matrizCorreta).map((linha, key) => (
            <Row className={style.gabaritoLinha} key={key}>
              <Col flex={1} className={style.number}>
                {linha}
              </Col>
              {simulado.tipo === "certo ou errado" ? (
                <>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 1 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 1
                        ? style.checked
                        : matrizCorreta[linha] === 1
                        ? style.correta
                        : ""
                    }
                  >
                    Certo
                  </Col>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 2 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 2
                        ? style.checked
                        : matrizCorreta[linha] === 2
                        ? style.correta
                        : ""
                    }
                  >
                    Errado
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 1 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 1
                        ? style.checked
                        : matrizCorreta[linha] === 1
                        ? style.correta
                        : ""
                    }
                  >
                    A
                  </Col>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 2 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 2
                        ? style.checked
                        : matrizCorreta[linha] === 2
                        ? style.correta
                        : ""
                    }
                  >
                    B
                  </Col>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 3 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 3
                        ? style.checked
                        : matrizCorreta[linha] === 3
                        ? style.correta
                        : ""
                    }
                  >
                    C
                  </Col>
                  <Col
                    flex={1}
                    style={
                      matrizCorreta[linha] === 4 ? { color: "#52c41a" } : {}
                    }
                    className={
                      matriz[linha] === 4
                        ? style.checked
                        : matrizCorreta[linha] === 4
                        ? style.correta
                        : ""
                    }
                  >
                    D
                  </Col>
                  {simulado.tipo === "múltipla escolha 5" ? (
                    <Col
                      flex={1}
                      style={
                        matrizCorreta[linha] === 5 ? { color: "#52c41a" } : {}
                      }
                      className={
                        matriz[linha] === 5
                          ? style.checked
                          : matrizCorreta[linha] === 5
                          ? style.correta
                          : ""
                      }
                    >
                      E
                    </Col>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Row>
          ))}
        </>
      ) : (
        <p>Visualização indisponível</p>
      )}
    </Modal>
  );
}
