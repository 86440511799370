const INITIAL_STATE = {
  materia: null,
  materias: null,
  loadMaterias: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_MATERIA_REQUEST':
    case 'GET_MATERIAS_REQUEST': {
      return { ...state, loadMaterias: true }
    }
    case 'GET_MATERIA_SUCCESS': {
      return { ...state, loadMaterias: false, materia: action.payload }
    }
    case 'GET_MATERIAS_SUCCESS': {
      return { ...state, loadMaterias: false, materias: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_MATERIA_FAILURE':
    case 'GET_MATERIAS_FAILURE': {
      return { ...INITIAL_STATE, loadMaterias: false }
    }

    /** STORE */
    case 'STORE_MATERIA_REQUEST': {
      return { ...state, loadMaterias: true }
    }
    case 'STORE_MATERIA_SUCCESS': {
      return { ...state, loadMaterias: false }
    }
    case 'STORE_MATERIA_FAILURE': {
      return { ...state, loadMaterias: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}