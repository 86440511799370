import { API_URL } from "../configs/config";

export const getOrgao = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_ORGAO_REQUEST" });
    fetch(`${API_URL}/orgao/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_ORGAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_ORGAO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getOrgaos = () => {
  return (dispatch) => {
    dispatch({ type: "GET_ORGAOS_REQUEST" });
    fetch(`${API_URL}/orgao`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_ORGAOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_ORGAOS_FAILURE",
          payload: error,
        })
      );
  };
};
