import React, { Component } from 'react'
import { Col, Row, Skeleton, Empty, Collapse } from 'antd';
import style from './style.module.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCargos } from '../../actions/Cargos.action'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import BarraHistorico from '../../components/BarraHistorico'
import { withRouter } from "react-router"
import { UF } from '../../images/bandeiras'
import RenderHelmet from '../../components/RenderHelmet'
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";
const { Panel } = Collapse

class Cargos extends Component {

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/cargos");
    window.scrollTo(0, 0)
    this.props.getCargos()
  }

  renderLoading = () => {
    return (
      <>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
      </>
    )
  }

  renderCargo = (cargo) => {
    var total =  0;
    return (
      <Col key={cargo.id} style={{marginBottom: 48, width: '100%'}} sm={{ span: 24 }} md={{ span: 12 }}>
        <div className={style.boxCargo}>
          <h3>{cargo.nome}</h3>
          <Collapse bordered={false} className={style.collapse}>
            <Panel className={style.panel} header={<p className={style.panelHeader}>Provas cadastradas: {cargo.concursos.length}</p>}>
              {
                cargo.concursos.map(concurso=> {
                  total += concurso.questoes.length
                  return <p key={concurso.id} className={style.collapseContent}><Link to={`/questoes?concursos[]=${concurso.id}`}><img style={{marginRight: 8, height: 16, marginTop: '-2px'}} alt={concurso.orgao.UF} src={UF[concurso.orgao.UF]} /> <LinkOutlined /> {concurso.nome} - {concurso.ano}, {concurso.orgao.nome}</Link></p>
                })
              }
            </Panel>
          </Collapse>

          <p className="no-margin">
            <span className="strong no-margin">Total de: </span>
            <Link to={`/questoes?cargos[]=${cargo.id}`}><LinkOutlined /> {total} questões</Link>
          </p>

        </div>
      </Col>
    )
  }

  renderCargos = (cargos) => {
    return cargos.map(cargo => {
      return this.renderCargo(cargo)
    })
  }

  render() {
    const { loadCargos, cargos } = this.props.cargos
    return (
      <div className="container">
        <RenderHelmet title={'Cade Direito: Cargos'} description={'Lista de cargos de concursos'}/>
        <BarraHistorico title={'Cargos'} match={ this.props.match }/>
        <Row gutter={[24,24]}>
          {
            cargos
              ? this.renderCargos(cargos)
              : (
                !loadCargos
                  ? <Empty description="Não há cargos disponíveis" style={{margin: '64px 0 128px 0'}} />
                  : <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
                )
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    cargos: state.cargos
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCargos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cargos))