const INITIAL_STATE = {
  orgao: null,
  orgaos: null,
  loadOrgaos: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_ORGAO_REQUEST':
    case 'GET_ORGAOS_REQUEST': {
      return { ...state, loadOrgaos: true }
    }
    case 'GET_ORGAO_SUCCESS': {
      return { ...state, loadOrgaos: false, orgao: action.payload }
    }
    case 'GET_ORGAOS_SUCCESS': {
      return { ...state, loadOrgaos: false, orgaos: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_ORGAO_FAILURE':
    case 'GET_ORGAOS_FAILURE': {
      return { ...INITIAL_STATE, loadOrgaos: false }
    }

    /** STORE */
    case 'STORE_ORGAO_REQUEST': {
      return { ...state, loadOrgaos: true }
    }
    case 'STORE_ORGAO_SUCCESS': {
      return { ...state, loadOrgaos: false}
    }
    case 'STORE_ORGAO_FAILURE': {
      return { ...state, loadOrgaos: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}