import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  Container,
  Title,
  SearchBar,
  SearchBarButton,
  ButtonClose,
  SearchBarButtonMobile,
} from "./styles.js";
import { useHistory } from "react-router-dom";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";

function BarraPesquisa() {
  const history = useHistory();

  const [texto, setTexto] = useState("");

  async function procurar() {
    history.push(`/questoes?enunciado=${texto}`);
    setTexto("");
  }

  function keyDown(ev) {
    if (ev.code === "Enter" || ev.code === "NumpadEnter") {
      procurar();
    }
  }

  return (
    <Container>
      <Title>Encontre mais questões</Title>
      <Row>
        <Col span={24}>
          <SearchBar
            placeholder="Digite ou cole aqui parte do enunciado"
            value={texto}
            onChange={(ev) => setTexto(ev.target.value)}
            prefix={<SearchOutlined />}
            onKeyDown={keyDown}
            suffix={
              <>
                <ButtonClose type="link" onClick={() => setTexto("")}>
                  <CloseOutlined />
                </ButtonClose>
                <SearchBarButton
                  onClick={() => procurar()}
                  disabled={texto === ""}
                >
                  Procurar
                </SearchBarButton>
              </>
            }
          />
          <SearchBarButtonMobile
            onClick={() => procurar()}
            disabled={texto === ""}
          >
            Procurar
          </SearchBarButtonMobile>
        </Col>
      </Row>
    </Container>
  );
}

export default BarraPesquisa;
