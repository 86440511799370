import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import Questao from "../../components/Questao";
import { Skeleton, Empty } from "antd";
import BarraHistorico from "../../components/BarraHistorico";
import { Helmet } from "react-helmet";
import { getQuestao } from "../../actions/Questoes.action";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";
/* import MaisQuestoes from "./maisquestoes"; */

import BarraPesquisa from "../../components/BarraPesquisa";

function QuestaoID({ match }) {
  const dispatch = useDispatch();

  window.scrollTo(0, 0);
  const { id } = match.params;

  const { questao, loadQuestoes } = useSelector((state) => state.questoes);

  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/questoes/" + id);
    dispatch(getQuestao(id));
  }, [id, dispatch]);

  function renderHeader(questao) {
    var div = document.createElement("div");
    div.innerHTML = questao.enunciado;
    var text = div.innerText.replace(/\s\s+/g, " ");

    //global.description = description;

    return (
      <Helmet>
        <title>{`Questão: ${text}`}</title>
        <meta name="description" content={`${text}`}></meta>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
    );
  }

  const title = questao && questao.cdid ? questao.cdid : "";
  return (
    <>
      <div className="container">
        <BarraHistorico title={title} match={match} />
        {!loadQuestoes ? (
          questao && questao.id ? (
            <>
              {renderHeader(questao)}
              <Questao key={questao.id} questao={questao}></Questao>
              <BarraPesquisa />
              {/*<MaisQuestoes idQuestao={questao.id} assunto={questao.assunto} />*/}
            </>
          ) : (
            <Empty
              description="Questão inexistente."
              style={{ margin: "64px 0 128px 0" }}
            />
          )
        ) : (
          <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
        )}
      </div>
    </>
  );
}

export default withRouter(QuestaoID);
