import { API_URL } from "../configs/config";

export function logar(usuario, senha) {
  return (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST" });
    fetch(
      `${API_URL}/login2?login=${encodeURIComponent(
        usuario
      )}&senha=${encodeURIComponent(senha)}`
    )
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        if (response.status === "sucesso") {
          return dispatch({
            type: "LOGIN_SUCCESS",
            payload: response,
          });
        } else {
          return dispatch({
            type: "LOGIN_FAILURE",
            payload: response,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: "LOGIN_FAILURE",
          payload: error,
        });
      });
  };
}

export function logarComFacebook(access_token) {
  return (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST" });
    fetch(
      `${API_URL}/loginWithFacebook?access_token=${encodeURIComponent(
        access_token
      )}`
    )
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        if (response.status === "sucesso") {
          return dispatch({
            type: "LOGIN_SUCCESS",
            payload: response,
          });
        } else {
          return dispatch({
            type: "LOGIN_FAILURE",
            payload: "error",
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: "LOGIN_FAILURE",
          payload: error,
        });
      });
  };
}

export function deslogar(accessToken) {
  return (dispatch) => {
    fetch(`${API_URL}/logout?accessToken=${encodeURIComponent(accessToken)}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        dispatch({
          type: "DESLOGAR",
        });
      })
      .catch((error) => {
        dispatch({
          type: "DESLOGAR",
        });
      });
  };
}

export function resetReducers() {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_RESET",
    });
  };
}
