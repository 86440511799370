import React, { Component } from "react";
import { API_URL } from "../../configs/config";
import { Skeleton, Row, Col } from "antd";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";

const COLORS = ["#52c41a", "#ff4d4f"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

class Estatisticas extends Component {
  state = {
    loadEstatisticas: false,
    estatisticas: null,
  };

  componentDidMount = () => {
    const { idQuestao } = this.props;

    this.setState({ loadEstatisticas: true });
    fetch(`${API_URL}/questao/${idQuestao}/estatisticas`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        this.setState({ estatisticas: response, loadEstatisticas: false });
      })
      .catch((error) => {});
  };

  getData = (estatisticas, tipoQuestao) => {
    var data = [
      {
        name: "A",
        uv: estatisticas.opcaoEscolhida[1],
      },
      {
        name: "B",
        uv: estatisticas.opcaoEscolhida[2],
      },
      {
        name: "C",
        uv: estatisticas.opcaoEscolhida[3],
      },
      {
        name: "D",
        uv: estatisticas.opcaoEscolhida[4],
      },
      {
        name: "E",
        uv: estatisticas.opcaoEscolhida[5],
      },
    ];

    switch (tipoQuestao) {
      case "certo ou errado": {
        return [
          {
            name: "Certo",
            uv: estatisticas.opcaoEscolhida[1],
          },
          {
            name: "Errado",
            uv: estatisticas.opcaoEscolhida[2],
          },
        ];
      }
      case "múltipla escolha 4": {
        return [data[0], data[1], data[2], data[3]];
      }
      case "múltipla escolha 5": {
        return data;
      }
      default: {
        return [];
      }
    }
  };

  render() {
    const { estatisticas, loadEstatisticas } = this.state;

    return (
      <>
        {loadEstatisticas ? (
          <Skeleton active></Skeleton>
        ) : (
          <>
            {estatisticas ? (
              <Row>
                <Col span={12}>
                  <Row>
                    <Col>
                      <div>
                        <b>Respondidas</b>
                        <span style={{ padding: "0 12px" }}>
                          {estatisticas.total}
                        </span>
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundColor: "#52c41a",
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            display: "inline-block",
                          }}
                        ></div>
                        <div style={{ display: "inline-block", marginLeft: 8 }}>
                          Acertos:
                        </div>
                        <span style={{ padding: "0 12px" }}>
                          {estatisticas.acertos}
                        </span>
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundColor: "#ff4d4f",
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            display: "inline-block",
                          }}
                        ></div>
                        <div style={{ display: "inline-block", marginLeft: 8 }}>
                          Erros:
                        </div>
                        <span style={{ padding: "0 12px" }}>
                          {estatisticas.erros}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <PieChart width={140} height={140}>
                        <Pie
                          data={[
                            { name: "Acertos", value: estatisticas.acertos },
                            { name: "Erros", value: estatisticas.erros },
                          ]}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={70}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {[
                            { name: "Acertos", value: estatisticas.acertos },
                            { name: "Erros", value: estatisticas.erros },
                          ].map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col>
                      <div>
                        <b>Opções escolhidas</b>
                      </div>
                      <BarChart
                        width={400}
                        height={200}
                        data={this.getData(
                          estatisticas,
                          this.props.tipoQuestao
                        )}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="uv" fill="#1890ff" />
                      </BarChart>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <p>Não há dados estatísticos da questão</p>
            )}
          </>
        )}
      </>
    );
  }
}

export default Estatisticas;
