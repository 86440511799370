import styled from "styled-components";

export const Container = styled.div``;

export const LegislacaoText = styled.div`
  margin: 64px 0 128px;
  p {
    text-indent: 32px;
  }

  .brasao {
    margin-bottom: 32px;
  }
  .header-title {
    color: var(--primary);
    text-align: center;
  }
  .titulo, .capitulo {
    text-align: center;
    font-size: 1.5em;
    color: var(--primary);
    text-decoration: underline: 
  }
  .num {
    font-weight: bold;
    color: var(--primary);
  }
  .foco {
    background-color: #e6f7ff;
    padding: 8px 12px;
    margin-left: -12px;
    margin-right: -12px;
    padding-bottom: 1em;
    margin-bottom: -1em;
  }
`;

export const Preambulo = styled.div`
  .preambulo-titulo {
    text-align: center;
  }
`;
