import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMaterias} from '../../actions/Materias.action'
import { getConcursos, getAnos } from '../../actions/Concursos.action'
import { getBancas} from '../../actions/Bancas.action'
import { getOrgaos } from '../../actions/Orgaos.action'
import { getCargos } from '../../actions/Cargos.action'
import { Input } from 'antd'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome" 
import { Select, Form, Row, Col, Button } from 'antd'
import { FilterOutlined, ClearOutlined } from '@ant-design/icons';
import style from './style.module.css'
import {
  acre, alagoas, amapa, amazonas, bahia, ceara, distritofederal, espiritosanto, goias,
  maranhao, matogrosso, matogrossosul, minasgerais, para, paraiba, parana, pernambuco, piaui,
  riodejaneiro, riograndedonorte, riograndedosul, rondonia, roraima, santacatarina, saopaulo, sergipe, tocantins, brasil
} from '../../images/bandeiras'

const { Option, OptGroup } = Select

class FiltroQuestoes extends Component {

  /**
   * Estado Inicial dos campos
   */
  initialState = {
    disciplinas: [],
    assuntos: [],
    concursos: [],
    orgaos: [],
    bancas: [],
    cargos: [],
    estados: [],
    anos: [],
    anoInicial: 1988,
    anoFinal: 2021,
    tipo: null,
    exibir: 'todas',
    mobileToggle: 'oculto'
  }
  state = this.initialState

  /**
   * Eventos disparados ao concluir a renderização
   */
  componentDidMount = () => {
    this.props.getMaterias()
    this.props.getConcursos()
    this.props.getAnos()
    this.props.getBancas()
    this.props.getOrgaos()
    this.props.getCargos()
  }

  /**
   * Renderiza as opções de estados
   */
  renderEstados = () => {
    return (
      <>
        <OptGroup label="Nível Nacional">
          <Option value="NACIONAL" key="27"><img alt="NACIONAL" className={style.bandeiraEstado} src={brasil} />Nível Nacional</Option>
        </OptGroup>
        <OptGroup label="Região Centro-Oeste">
          <Option value="DF" key="7"><img alt="DF" className={style.bandeiraEstado} src={distritofederal} />DF</Option>
          <Option value="GO" key="8"><img alt="GO" className={style.bandeiraEstado} src={goias} />GO</Option>
          <Option value="MS" key="9"><img alt="MS" className={style.bandeiraEstado} src={matogrossosul} />MS</Option>
           <Option value="MT" key="10"><img alt="MT" className={style.bandeiraEstado} src={matogrosso} />MT</Option>
        </OptGroup>
        <OptGroup label="Região Nordeste">
          <Option value="AL" key="18"><img alt="AL" className={style.bandeiraEstado} src={alagoas} />AL</Option>
          <Option value="BA" key="19"><img alt="BA" className={style.bandeiraEstado} src={bahia} />BA</Option>
          <Option value="CE" key="20"><img alt="CE" className={style.bandeiraEstado} src={ceara} />CE</Option>
          <Option value="MA" key="21"><img alt="MA" className={style.bandeiraEstado} src={maranhao} />MA</Option>
          <Option value="PB" key="22"><img alt="PB" className={style.bandeiraEstado} src={paraiba} />PB</Option>
          <Option value="PE" key="23"><img alt="PE" className={style.bandeiraEstado} src={pernambuco} />PE</Option>
          <Option value="PI" key="24"><img alt="PI" className={style.bandeiraEstado} src={piaui} />PI</Option>
          <Option value="RN" key="25"><img alt="RN" className={style.bandeiraEstado} src={riograndedonorte} />RN</Option>
          <Option value="SE" key="26"><img alt="SE" className={style.bandeiraEstado} src={sergipe} />SE</Option>
        </OptGroup>
        <OptGroup label="Região Norte">
          <Option value="AC" key="11"><img alt="AC" className={style.bandeiraEstado} src={acre} />AC</Option>
          <Option value="AM" key="12"><img alt="AM" className={style.bandeiraEstado} src={amazonas} />AM</Option>
          <Option value="AP" key="13"><img alt="AP" className={style.bandeiraEstado} src={amapa} />AP</Option>
          <Option value="PA" key="14"><img alt="PA" className={style.bandeiraEstado} src={para} />PA</Option>
          <Option value="RO" key="15"><img alt="RO" className={style.bandeiraEstado} src={rondonia} />RO</Option>
          <Option value="RR" key="16"><img alt="RR" className={style.bandeiraEstado} src={roraima} />RR</Option>
          <Option value="TO" key="17"><img alt="TO" className={style.bandeiraEstado} src={tocantins} />TO</Option>
        </OptGroup>
        <OptGroup label="Região Sudeste" >
          <Option value="ES" key="0"><img alt="ES" className={style.bandeiraEstado} src={espiritosanto} />ES</Option>
          <Option value="MG" key="1"><img alt="MG" className={style.bandeiraEstado} src={minasgerais} />MG</Option>
          <Option value="RJ" key="2"><img alt="RJ" className={style.bandeiraEstado} src={riodejaneiro} />RJ</Option>
          <Option value="SP" key="3"><img alt="SP" className={style.bandeiraEstado} src={saopaulo} />SP</Option>
        </OptGroup>
        <OptGroup label="Região Sul" >
          <Option value="PR" key="4"><img alt="PR" className={style.bandeiraEstado} src={parana} />PR</Option>
          <Option value="RS" key="5"><img alt="RS" className={style.bandeiraEstado} src={riograndedosul} />RS</Option>
          <Option value="SC" key="6"><img alt="SC" className={style.bandeiraEstado} src={santacatarina} />SC</Option>
        </OptGroup>
      </>
    )
  }

  /**
   * Reseta os campos
   */
  resetFields = () => {
    this.setState(this.initialState)
  }

  /**
   * Evento disparado ao finalizar os filtros
   * @param {*} values 
   */
  onFinish = values => {
    this.props.filtros(this.state)
    this.toggleFilters('oculto')
  };

  /**
   * Expande ou esconde os filtros para dispositivos mobile
   * @param {*} to 
   */
  toggleFilters = (to = null) => {
    if (!to) {
      to = this.state.mobileToggle === 'expandido' ? 'oculto' : 'expandido'
    }
    this.setState({'mobileToggle' : to})
  }

  /**
   * Renderiza as opções de disciplinas
   */
  renderDisciplinas = () => {
    const { loadMaterias, materias } = this.props.materias
    return (
      !loadMaterias && materias
      ? materias.map(m => {
          return <Option value={m.id} key={m.id}>{m.nome}</Option>
        })
      : null
    )
  }

  /**
   * Renderiza as opções de assuntos
   */
  renderAssuntos = () => {
    const { loadMaterias, materias } = this.props.materias
    if (this.state.disciplinas.length === 0) {}
    return(
      !loadMaterias && materias
        ? materias.map(m => {
          if (this.state.disciplinas.length > 0) { 
            if (this.state.disciplinas.indexOf(m.id) === -1) {
              return false
            }
          }
          return <OptGroup key={m.id} label={m.nome}>
            {
              m.assuntos.map(a => {
                return <Option value={a.id} key={a.id}>{a.nome}</Option>
              })
            }
          </OptGroup>
        })
      : null
    )
  }

  /**
   * Renderiza as opções de cargos
   */
  renderCargos = () => {
    const { cargos: { loadCargos, cargos } } = this.props
    return (
      !loadCargos && cargos
        ? cargos.map(c => {
          // Sincroniza com o ano
          var anoExiste = c.concursos.find(p => this.state.anos.includes(p.ano))
          if (this.state.anos.length > 0 && !anoExiste) { return false }

          // Sincroniza com o estado
          var estadoExiste = c.concursos.find(p => this.state.estados.includes(p.orgao.UF))
          if (this.state.estados.length > 0 && !estadoExiste) { return false }

          //Sincroniza com o tipo de prova
          var tipoExiste = c.concursos.find(p => {
            var tipo = null
            switch (this.state.tipo) {
              case 1: { tipo = 'múltipla escolha 5'; break; }
              case 2: { tipo = 'múltipla escolha 4'; break; }
              case 3: { tipo = 'certo ou errado'; break; }
              default: { tipo = null}
            }
            return p.questoes[0].tipo === tipo
          })
          if (this.state.tipo && !tipoExiste) { return false }
          
          return <Option value={c.id} key={c.id}>{c.nome}</Option>
        })
        : null
    )
  }

  /**
   * Renderiza as opções de bancas
   */
  renderBancas = () => {
    const { bancas: { loadBancas, bancas } } = this.props
    return (
      !loadBancas && bancas
        ? bancas.map(b => {
          // Sincroniza com o ano
          var anoExiste = b.concursos.find(p => this.state.anos.includes(p.ano))
          if (this.state.anos.length > 0 && !anoExiste) { return false }

          // Sincroniza com o estado
          var estadoExiste = b.concursos.find(p => this.state.estados.includes(p.orgao.UF))
          if (this.state.estados.length > 0 && !estadoExiste) { return false }

          //Sincroniza com o tipo de prova
          var tipoExiste = b.concursos.find(p => {
            var tipo = null
            switch (this.state.tipo) {
              case 1: { tipo = 'múltipla escolha 5'; break; }
              case 2: { tipo = 'múltipla escolha 4'; break; }
              case 3: { tipo = 'certo ou errado'; break; }
              default: { tipo = null}
            }
            return p.questoes[0].tipo === tipo
          })
          if (this.state.tipo && !tipoExiste) { return false }

          return <Option value={b.id} key={b.id}>{b.nome}</Option>
        })
        : null
    )
  }

  /**
   * Renderiza as opções de órgãos
   */
  renderOrgaos = () => {
    const { orgaos: { loadOrgaos, orgaos } } = this.props
    return(
      !loadOrgaos && orgaos
        ? orgaos.map(o => {
          // Sincroniza com o ano
          var anoExiste = o.concursos.find(p => this.state.anos.includes(p.ano))
          if (this.state.anos.length > 0 && !anoExiste) { return false }

          // Sincroniza com o estado
          var estadoExiste = this.state.estados.includes(o.UF)
          if (this.state.estados.length > 0 && !estadoExiste) { return false } 

          //Sincroniza com o tipo de prova
          var tipoExiste = o.concursos.find(p => {
            var tipo = null
            switch (this.state.tipo) {
              case 1: { tipo = 'múltipla escolha 5'; break; }
              case 2: { tipo = 'múltipla escolha 4'; break; }
              case 3: { tipo = 'certo ou errado'; break; }
              default: { tipo = null}
            }
            return p.questoes[0].tipo === tipo
          })
          if (this.state.tipo && !tipoExiste) { return false }

          return <Option value={o.id} key={o.id}>{o.nome}</Option>
        })
      : null
    )
  }

  /**
   * Renderiza as opções de anos
   */
  renderAnos = () => {

  }

  /**
   * Evento disparado ao alterar as disciplinas
   * @param {*} values 
   */
  changeDisciplinas = values => {
    this.setState({ 'disciplinas': values })
  }

  /**
   * Evento disparado ao alterar os assuntos
   * @param {*} values 
   */
  changeAssuntos = values => {
    /*if (this.state.disciplinas.length === 0) {
      let novo = values.filter(x => !this.state.assuntos.includes(x));
      if (novo) {
        novo = novo[0]
        let disciplina = this.props.materias.materias.find(m => {
          return m.assuntos.find(a => { 
            return a.id === novo
          })
        })
        if (disciplina) {
          let idDisciplina = disciplina.id
          if (!this.state.disciplinas.includes(idDisciplina)) {
            this.setState({ 'disciplinas': [...this.state.disciplinas, idDisciplina] })
          }
        }
      }
    }*/
    this.setState({ 'assuntos': values })
  }

  /**
   * Função principal de renderização do componente
   */
  render() {
    var {
      materias: { materias },
      concursos: { anos, loadAnos },
      bancas: { bancas },
      orgaos: { orgaos },
      cargos: { cargos },
      login: { isAuthenticate }
    } = this.props
    const { mobileToggle } = this.state

    return (
      <div data-toggle={mobileToggle}>
        <Form
          className={style.form}
          onFinish={this.onFinish}
        >
          <div style={{overflowX: 'hidden',overflowY: 'auto', height: '100%'}}>
          <Row gutter={24}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Form.Item>
                <span>Exibir</span>
                {
                  isAuthenticate
                    ? (
                        <Select
                        allowClear
                        name="exibir"
                        value={this.state.exibir}
                        onChange={(value) => this.setState({'exibir': value})}
                        placeholder="Selecione o modo de exibição">
                        <Option value={'todas'} key={1}>Todas as questões</Option>
                        <Option value={'não respondidas'} key={2}>Apenas não respondidas</Option>
                        <Option value={'respondidas'} key={3}>Apenas respondidas</Option>
                        <Option value={'certas'} key={4}>Respondidas certas</Option>
                        <Option value={'erradas'} key={5}>Respondidas erradas</Option>
                      </Select>
                    )
                    : (
                      <Input disabled placeholder="Faça o login para ativar o filtro" className={style.disabled}/> 
                    )
                }

              </Form.Item>
            </Col>
              
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Form.Item>
                <span>Tipo de questão</span>
                <Select
                  allowClear
                  name="tipo"
                  value={this.state.tipo}
                  onChange={(values) => this.setState({'tipo': values})}
                  placeholder="Selecione o tipo de questão">
                  <Option value={1} key={1}>Múltipla escolha com 5 alternativas</Option>
                  <Option value={2} key={2}>Múltipla escolha com 4 alternativas</Option>
                  <Option value={3} key={3}>Certo ou errado</Option>
                </Select>
              </Form.Item>
            </Col>
              
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Form.Item>
                <span>Estados</span>
                <Select
                  allowClear
                  name="estados"
                  mode="multiple"
                  value={this.state.estados}
                  onChange={(values) => this.setState({ 'estados': values })}
                  placeholder="Selecione os estados">
                  {
                    this.renderEstados()
                  }
                </Select>
              </Form.Item>
            </Col>
              
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Form.Item>
                <span>Ano</span>
                <Select
                  allowClear
                  name="anos"
                  mode="multiple"
                  value={this.state.anos}
                  onChange={(values) => this.setState({ 'anos': values })}
                  placeholder="Selecione os anos">
                  {
                    !loadAnos && anos
                    ? anos.map(y =>
                      <Option value={y} key={y}>{y}</Option>
                      )
                    : null
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item>
                <span>Disciplinas</span>
                <Select
                    allowClear
                    disabled={materias ? false : true}
                    name="disciplinas"
                    mode="multiple"
                    value={this.state.disciplinas}
                    placeholder="Selecione as disciplinas"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                    onChange={(values) => { this.changeDisciplinas(values) }}
                  >
                    { this.renderDisciplinas() }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item>
                <span>Assuntos</span>
                <Select
                  allowClear
                  disabled={ materias ? false : true}
                  name="assuntos"
                  mode="multiple"
                  value={this.state.assuntos}
                  optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option.children) {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      return false
                    }
                  }
                  onChange={(values) => this.changeAssuntos(values) }
                  placeholder="Selecione os assuntos">
                    { this.renderAssuntos() }
                </Select>
              </Form.Item>
            </Col>

            {/*<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item>
                <span>Concursos</span>
                <Select
                  allowClear
                  disabled={ concursos ? false : true}
                  name="concursos"
                  mode="multiple"
                  value={this.state.concursos}
                  onChange={(values) => this.setState({ 'concursos': values })}
                  placeholder="Selecione os concursos">
                  {
                    !loadConcursos && concursos
                    ? concursos.map(c =>
                      <Option value={c.id} key={c.id}>{c.nome}</Option>
                      )
                    : null
                  }
                </Select>
              </Form.Item>
            </Col>*/}

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item>
                <span>Cargos</span>
                <Select
                  allowClear
                  disabled={ cargos ? false : true}
                  name="cargos"
                  mode="multiple"
                  value={this.state.cargos}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  onChange={(values) => this.setState({ 'cargos': values })}
                  placeholder="Selecione os cargos">
                    { this.renderCargos() }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item>
                <span>Órgãos</span>
                <Select
                  allowClear
                  disabled={ orgaos ? false : true}
                  name="orgaos"
                  mode="multiple"
                  value={this.state.orgaos}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  onChange={(values) => this.setState({ 'orgaos': values })}
                  placeholder="Selecione os órgãos">
                    { this.renderOrgaos() }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item>
                <span>Bancas</span>
                <Select
                  allowClear
                  disabled={ bancas ? false : true}
                  name="bancas"
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  value={this.state.bancas}
                  onChange={(values) => this.setState({ 'bancas': values })}
                  placeholder="Selecione as bancas">
                    { this.renderBancas() }
                </Select>
              </Form.Item>
            </Col>

            <Col className={style.buttons + ' ' + style.buttonsFiltrar} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} style={{ display:   'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px' }}>
                <Button style={{ margin: '0 8px' }} onClick={() => this.resetFields()}><ClearOutlined />Limpar</Button>
                <Button type="primary" htmlType="submit"><FilterOutlined />Filtrar</Button>
            </Col>
          </Row>

          <div className={style.toggleButton} onClick={() => this.toggleFilters()}>
            <span><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></span>
          </div>
          </div>
        </Form>
        <div className={style.overlay} onClick={() => this.toggleFilters('oculto')}></div>
      </div>
    )
  }
}

/**
 * Mapeia o estado para as propriedades
 * @param {*} state 
 */
const mapStateToProps = state =>
  ({
    materias: state.materias,
    concursos: state.concursos,
    bancas: state.bancas,
    orgaos: state.orgaos,
    cargos: state.cargos,
    login: state.login
  })

/**
  * Mapeia as ações para as propriedades
  * @param {*} dispatch 
  */
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMaterias, getConcursos, getAnos, getBancas, getOrgaos, getCargos }, dispatch)

/**
 * Conecta os estados e ações com as propriedades da classe
 */
export default connect(mapStateToProps, mapDispatchToProps)(FiltroQuestoes)

