import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { Box } from "./styles";

import { Badge, Space, Switch } from "antd";

export default function Resultados({ resultados }) {
  console.log(resultados);

  // pesquisar por artigos, etc.

  /*return (
    <Row>
      {resultados && (
        <Box>
          <span className="mr-4">Constituição Federal</span>
          <Badge
            count={resultados.length}
            style={{ backgroundColor: "#108ee9" }}
          />
        </Box>
      )}
    </Row>
  );*/

  function runSuperiores(c, superior) {
    let t = c;
    if(superior && superior.titulo) {
      t = superior.titulo + ' ' + t;
    }
    if(superior && superior.titulo) {
      return runSuperiores(t, superior.superiores);
    }
    return t;
  }

  return (
    <Row gutter={[48, 48]}>
      {resultados &&
        resultados.map((r) => {
         
          var title = runSuperiores('', r);
          console.log(title);

          let text = r.texto.length <= 100 ? r.texto : `${r.texto.substr(0, 97)}...`;
          return (
            <Col xl={6} lg={8} md={12} sm={24}>
              <Link to={`/legislacao/federal/${r.url}`}>
                <div>
                  <p style={{fontWeight: 'bold', marginBottom: 4}}>{title} da CF.</p>
                  <p style={{color: 'rgba(0,0,0,.85)'}}>{text}</p>
                </div>
                </Link>
            </Col>
          );
        })}
    </Row>
  );
}
