import { combineReducers } from 'redux'

import LoginReducer from './Login.reducer'
import RegistrarReducer from './Registrar.reducer'
import MateriasReducers from './Materias.reducer'
import ConcursosReducers from './Concursos.reducer'
import BancasReducers from './Bancas.reducer'
import CargosReducers from './Cargos.reducer'
import OrgaosReducers from './Orgaos.reducer'
import QuestoesReducers from './Questoes.reducer'
import ComentariosReducers from './Comentarios.reducer'
import UsuariosReducers from './Usuarios.reducer'
import PersistOriginReducer from './PersistOrigin.reducer'
import QuizReducers from './Quiz.reducer'

const reducers = combineReducers({
  login: LoginReducer,
  persistOrigin: PersistOriginReducer,
  registrar: RegistrarReducer,
  materias: MateriasReducers,
  concursos: ConcursosReducers,
  bancas: BancasReducers,
  cargos: CargosReducers,
  orgaos: OrgaosReducers,
  questoes: QuestoesReducers,
  comentarios: ComentariosReducers,
  usuarios: UsuariosReducers,
  quiz: QuizReducers,
})

export default reducers