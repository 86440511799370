import React, { Component } from 'react'
import { Col, Row, Skeleton, Empty, Collapse } from 'antd';
import style from './style.module.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOrgaos } from '../../actions/Orgaos.action'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import BarraHistorico from '../../components/BarraHistorico'
import { withRouter } from "react-router"
import {
  acre, alagoas, amapa, amazonas,
  bahia, ceara, distritofederal, espiritosanto,
  goias, maranhao, matogrosso, matogrossosul,
  minasgerais, para, paraiba, parana,
  pernambuco, piaui, riodejaneiro, riograndedonorte,
  riograndedosul, rondonia, roraima, santacatarina,
  saopaulo, sergipe, tocantins
} from '../../images/bandeiras'
import RenderHelmet from '../../components/RenderHelmet'
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

const { Panel } = Collapse

class Orgaos extends Component {

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/orgaos");
    window.scrollTo(0, 0)
    this.props.getOrgaos()
  }

  renderFlag = (uf) => {
    switch (uf.toUpperCase()) {
      case "DF": return <img alt="DF" className={style.bandeiraEstado} src={distritofederal} />
      case "GO": return <img alt="GO" className={style.bandeiraEstado} src={goias} />
      case "MS": return <img alt="MS" className={style.bandeiraEstado} src={matogrossosul} />
      case "MT": return <img alt="MT" className={style.bandeiraEstado} src={matogrosso} />
      case "AL": return <img alt="AL" className={style.bandeiraEstado} src={alagoas} />
      case "BA": return <img alt="BA" className={style.bandeiraEstado} src={bahia} />
      case "CE": return <img alt="CE" className={style.bandeiraEstado} src={ceara} />
      case "MA": return <img alt="MA" className={style.bandeiraEstado} src={maranhao} />
      case "PB": return <img alt="PB" className={style.bandeiraEstado} src={paraiba} />
      case "PE": return <img alt="PE" className={style.bandeiraEstado} src={pernambuco} />
      case "PI": return <img alt="PI" className={style.bandeiraEstado} src={piaui} />
      case "RN": return <img alt="RN" className={style.bandeiraEstado} src={riograndedonorte} />
      case "SE": return <img alt="SE" className={style.bandeiraEstado} src={sergipe} />
      case "AC": return <img alt="AC" className={style.bandeiraEstado} src={acre} />
      case "AM": return <img alt="AM" className={style.bandeiraEstado} src={amazonas} />
      case "AP": return <img alt="AP" className={style.bandeiraEstado} src={amapa} />
      case "PA": return <img alt="PA" className={style.bandeiraEstado} src={para} />
      case "RO": return <img alt="RO" className={style.bandeiraEstado} src={rondonia} />
      case "RR": return <img alt="RR" className={style.bandeiraEstado} src={roraima} />
      case "TO": return <img alt="TO" className={style.bandeiraEstado} src={tocantins} />
      case "ES": return <img alt="ES" className={style.bandeiraEstado} src={espiritosanto} />
      case "MG": return <img alt="MG" className={style.bandeiraEstado} src={minasgerais} />
      case "RJ": return <img alt="RJ" className={style.bandeiraEstado} src={riodejaneiro} />
      case "SP": return <img alt="SP" className={style.bandeiraEstado} src={saopaulo} />
      case "PR": return <img alt="PR" className={style.bandeiraEstado} src={parana} />
      case "RS": return <img alt="RS" className={style.bandeiraEstado} src={riograndedosul} />
      case "SC": return <img alt="SC" className={style.bandeiraEstado} src={santacatarina} />
      default: return '';
    }
  }

  renderLoading = () => {
    return (
      <>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
      </>
    )
  }

  renderOrgao = (orgao) => {
    var total = 0;
    return (
      <Col key={orgao.id} style={{marginBottom: 48, width: '100%'}} sm={{ span: 24 }} md={{ span: 12 }}>
        <div className={style.boxOrgao}>
          <h3 style={{ lineHeight: '1em' }}>{orgao.nome}</h3>
          {
            orgao.site
              ? <a href={orgao.site} target="_BLANK" rel="noopener noreferrer"><LinkOutlined /> {orgao.site}</a>
              : ''
          }
          <Collapse bordered={false} className={style.collapse}>
            <Panel className={style.panel} header={<p className={style.panelHeader}>Concursos:</p>}>
              {
                orgao.concursos.map(concurso => {
                  total += concurso.questoes.length
                  return <p key={concurso.id} className={style.collapseContent}><Link to={`/questoes?concursos[]=${concurso.id}`}><LinkOutlined /> {concurso.nome} - {concurso.ano}</Link></p>
                })
              }
            </Panel>
          </Collapse>

          <p className="no-margin">
            <span className="strong no-margin">Total de: </span>
            <Link to={`/questoes?orgaos[]=${orgao.id}`}><LinkOutlined /> {total} questões</Link>
          </p>

        </div>
      </Col>
    )
  }

  renderOrgaos = (orgaos) => {
    return orgaos.map(orgao => {
      return this.renderOrgao(orgao)
    })
  }

  render() {
    const { loadOrgaos, orgaos } = this.props.orgaos

    return (
      <div className="container">
        <RenderHelmet title={'Cade Direito: Órgãos'} description={'Lista de órgãos de concursos'}/>
        <BarraHistorico title={'Orgaos'} match={ this.props.match }/>
        <Row gutter={[24,24]}>
          {
            orgaos
              ? this.renderOrgaos(orgaos)
              : (
                !loadOrgaos
                  ? <Empty description="Não há órgãos' disponíveis" style={{ margin: '64px 0 128px 0' }} />
                  : <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
              )
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    orgaos: state.orgaos
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getOrgaos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orgaos))