import { API_URL } from "../configs/config";

export const getMateria = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_MATERIA_REQUEST" });
    fetch(`${API_URL}/materia/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_MATERIA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_MATERIA_FAILURE",
          payload: error,
        })
      );
  };
};

export const getMaterias = () => {
  return (dispatch) => {
    dispatch({ type: "GET_MATERIAS_REQUEST" });
    fetch(`${API_URL}/materia`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_MATERIAS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_MATERIAS_FAILURE",
          payload: error,
        })
      );
  };
};
