import React, { Component } from "react";
import { Comment, Tooltip, Avatar, Form, Input, Button, Alert } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/pt-br";
import { API_URL } from "../../configs/config";
import { FormInstance } from "antd/lib/form";
import style from "./style.module.css";

const { TextArea } = Input;

class Comentario extends Component {
  formRef = React.createRef(FormInstance);

  state = {
    responderActive: false,
    liked: false,
    disliked: false,
    feedbackComentario: null,
    likes: 0,
    dislikes: 0,
  };

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    moment.locale("pt-br");
    //document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    //document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      setTimeout(() => {
        this.setState({ responderActive: false });
      }, 100);
    }
  }

  generateColor = (nome) => {
    return (
      "rgb(" +
      (nome[0].toUpperCase().charCodeAt() - 65) * 10 +
      "," +
      (nome[1].toUpperCase().charCodeAt() - 65) * 10 +
      "," +
      (nome[2].toUpperCase().charCodeAt() - 65) * 10 +
      ")"
    );
  };

  comentarQuestao = (values) => {
    const {
      comentario: { idQuestao, id },
      login: { accessToken },
    } = this.props;
    this.formRef.current.setFieldsValue({ novoComentario: "" });

    let headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
    let opts = {
      texto: values.novoComentario,
      idOutroComentario: id,
    };

    const body = JSON.stringify(opts);
    fetch(`${API_URL}/questao/${idQuestao}/comentario`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        if (!response.id) {
          this.setState({
            feedbackComentario: {
              correto: false,
              feedback: "Erro ao tentar registrar o comentário",
            },
          });
        } else {
          this.setState({
            feedbackComentario: {
              correto: true,
              feedback: "Comentário registrado. Aguarde a aprovação",
            },
          });
        }
      })
      .catch((error) =>
        this.setState({
          feedbackComentario: {
            correto: false,
            feedback: "Erro ao tentar registrar o comentário",
          },
        })
      );

    setTimeout(() => {
      this.setState({ feedbackComentario: null });
    }, 3000);
  };

  renderPerfil = (perfil) => {
    var color = "gray";
    var text = "Usuário";
    switch (perfil) {
      case "ESTUDANTE": {
        color = "darkblue";
        text = "ESTUDANTE";
        break;
      }
      case "PROFESSOR": {
        color = "purple";
        text = "PROFESSOR";
        break;
      }
      case "PROFISSIONAL": {
        color = "brown";
        text = "PROFISSIONAL DE DIREITO";
        break;
      }
      default: {
      }
    }
    return (
      <small className={style.perfil} style={{ color }}>
        {text}
      </small>
    );
  };

  render() {
    const {
      comentario,
      login: { isAuthenticate },
    } = this.props;
    const { responderActive, feedbackComentario } = this.state;

    if (!comentario) {
      return false;
    }

    return (
      <>
        <Comment
          author={
            <span>
              {comentario.usuario.usuario}{" "}
              {this.renderPerfil(comentario.usuario.perfil)}
            </span>
          }
          avatar={
            <Avatar
              size={32}
              style={{
                backgroundColor: this.generateColor(comentario.usuario.usuario),
              }}
              alt={comentario.usuario.usuario}
            >
              {comentario.usuario.usuario[0]}
            </Avatar>
          }
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: comentario.texto,
              }}
            ></span>
          }
          datetime={
            <Tooltip
              title={moment(comentario.created_at).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
            >
              <span>{moment(comentario.created_at).fromNow()}</span>
            </Tooltip>
          }
        >
          <div ref={this.setWrapperRef}>
            {/*this.renderActions()*/}
            {responderActive ? (
              isAuthenticate ? (
                <div>
                  <Form
                    ref={this.formRef}
                    onFinish={this.comentarQuestao}
                    rules={[
                      {
                        required: true,
                        message: "A mensagem não pode estar vazia.",
                      },
                    ]}
                  >
                    <Form.Item name="novoComentario">
                      <TextArea value={"123"} rows={4} />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary">
                        Comentar
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              ) : (
                <Alert
                  message="Faça o login para poder responder ao comentário."
                  type="info"
                  showIcon
                />
              )
            ) : (
              ""
            )}
          </div>
          {feedbackComentario ? (
            <Alert
              message={
                feedbackComentario.correto
                  ? feedbackComentario.feedback
                  : feedbackComentario.feedback
              }
              type={feedbackComentario.correto ? "success" : "error"}
              showIcon
            />
          ) : null}
          {this.props.children}
        </Comment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

//const mapDispatchToProps = dispatch =>
//bindActionCreators({}, dispatch)

export default connect(mapStateToProps /*, mapDispatchToProps*/)(Comentario);
