import React, { Component } from 'react'
import { Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'


class BarraHistorico extends Component {

  link = (path, e, key, lastKey) => {
    const title = this.props.title || ''
    var to = ''
    const splitPath = path.split('/')
    for (var i = 1; i <= key; i++) {
      to += `/${splitPath[i]}`
    }

    if (key === 0) {
      return <Link to={`/`}><HomeOutlined /></Link>
    }
    if (key === lastKey) {
      return <span>{title}</span>
    }
    return <Link to={to}>{e}</Link>
  }

  render() {
    const { path } = this.props.match
    return (
      <Breadcrumb style={{ margin: '24px 0', color: 'red' }}>
        {
          path.split('/').map((e, key) => {
            const lastKey = path.split('/').length - 1
            return (
              <Breadcrumb.Item key={key}>
                { this.link(path, e, key, lastKey)}
              </Breadcrumb.Item>
            )
          })
        }
      </Breadcrumb>
    )
  }
}


//<Link to={`/`}><HomeOutlined /></Link>: <Link to={`/${e}`}>{e}</Link>

export default BarraHistorico