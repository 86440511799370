import React, { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router";
import {
  Row,
  Col,
  Empty,
  Progress,
  Skeleton,
  Affix,
  Button,
  Table,
  Tag,
  Card,
} from "antd";
import { API_URL } from "../../configs/config";
import Questao from "../../components/Questao";
import BarraHistorico from "../../components/BarraHistorico";
import swal from "sweetalert";
import { SimuladoInfo, Round } from "./styles.js";

function montarGabarito(prova, matrizProva, timer) {
  const columns = [
    {
      title: "Questão",
      dataIndex: "questao",
      key: "questao",
    },
    {
      title: "Opção escolhida",
      dataIndex: "opcaoEscolhida",
      key: "opcaoEscolhida",
      render: (opcaoEscolhida) => <Round>{opcaoEscolhida}</Round>,
    },
    {
      title: "Opção correta",
      dataIndex: "opcaoCorreta",
      key: "opcaoCorreta",
      render: (opcaoCorreta) => <Round>{opcaoCorreta}</Round>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "ACERTOU" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "ver",
      key: "ver",
      render: () => <a href="/">Ver questão</a>,
    },
  ];

  const dataSource = [];

  prova.questoes.map((questao, index) => {
    var resposta = matrizProva.find((matriz) => {
      return matriz.id === questao.id;
    });
    return dataSource.push({
      questao: index + 1,
      opcaoEscolhida: resposta
        ? ["A", "B", "C", "D", "E"][resposta.response.respostaSelecionada - 1]
        : "-",
      opcaoCorreta: ["A", "B", "C", "D", "E"][questao.opcaoCorreta - 1],
      status: resposta
        ? resposta.response.correto
          ? "ACERTOU"
          : "ERROU"
        : "ERROU",
    });
  });

  var acertos = 0;
  matrizProva.map((matriz) => {
    if (matriz.response.correto) {
      acertos++;
    }
    return true;
  });

  var date = new Date(0);
  date.setSeconds(timer);
  var timeString = date.toISOString().substr(11, 8);

  return (
    <>
      <Row>
        <h3 style={{ marginBottom: 24 }}>
          {prova.banca.nome} - {prova.orgao.nome} - {prova.nome} - {prova.ano}
        </h3>
      </Row>
      <Row>
        <Col sm={12}>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Col>
        <Col sm={12}>
          <div className="site-card-border-less-wrapper">
            <Card title="Resumo" bordered={true} style={{ margin: "0 24px" }}>
              <p>
                <b>Total de questões:</b> {prova.questoes.length}
              </p>
              <p>
                <b>Acertos:</b> {acertos}
              </p>
              <p>
                <b>Erros:</b> {prova.questoes.length - acertos}
              </p>
              <p>
                <b>Aproveitamento:</b> {(acertos * 100) / prova.questoes.length}
                %
              </p>
              <p>
                <b>Tempo de duração: </b> {timeString}
              </p>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
}

function Prova({ match }) {
  const { id } = match.params;

  const [loadingProva, setLoadingProva] = useState(true);
  const [prova, setProva] = useState(null);
  const [mostrarGabarito, setMostrarGabarito] = useState(false);

  useEffect(() => {
    async function loadProva() {
      setLoadingProva(true);
      let headers = new Headers({
        "Content-Type": "application/json",
      });
      const provaResponse = await fetch(`${API_URL}/concurso/${id}`, {
        method: "get",
        headers,
      });
      const _prova = await provaResponse.json();
      setProva(_prova);
      setLoadingProva(false);
    }
    loadProva();
    window.scrollTo(0, 0);
  }, [id]);

  const [loadingQuestoes, setLoadingQuestoes] = useState(true);
  const [questoes, setQuestoes] = useState(null);

  useEffect(() => {
    async function loadQuestoes() {
      var body = `?page=${1}&size=200&order=ASC`;
      body += `&concursos[]=${id}`;
      setLoadingQuestoes(true);
      let headers = new Headers({
        "Content-Type": "application/json",
      });
      const questoesResponse = await fetch(`${API_URL}/questao${body}`, {
        method: "get",
        headers,
      });
      const _questoes = await questoesResponse.json();
      setQuestoes(_questoes.data);
      setLoadingQuestoes(false);
    }
    loadQuestoes();
  }, [id]);

  const [timerActive, setTimerActive] = useState(false);
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (!loadingQuestoes && !loadingProva) {
        if (timerActive) {
          setTimer(timer + 1);
        }
      }
    }, 1000);
    setTimerActive(true);
  }, [timer, loadingQuestoes, loadingProva, timerActive]);
  var date = new Date(0);
  date.setSeconds(timer);
  var timeString = date.toISOString().substr(11, 8);

  const [matrizProva, setMatrizProva] = useState([]);
  function callback(id, response) {
    let mp = matrizProva.find((mp) => mp.id === id);
    if (!mp) {
      setMatrizProva([...matrizProva, { id, response }]);
    } else {
      mp.id = id;
      mp.response = response;
    }
  }

  var percent = 0;
  if (questoes) {
    percent = parseFloat(
      ((matrizProva.length / questoes.length) * 100).toFixed(1)
    );
  }

  function finalizarProva() {
    swal({
      title: "Deseja finalizar a prova?",
      text: "Quando finalizada, o resultado será exibido junto com o gabarito.",
      icon: "warning",
      buttons: ["Cancelar", "Sim, finalizar!"],
      dangerMode: true,
    }).then((confirmado) => {
      if (confirmado) {
        setMostrarGabarito(true);
        setTimerActive(false);
        swal("Prova finalizada", {
          icon: "success",
        });
      }
    });
  }

  if (mostrarGabarito) {
    return (
      <div className="container">
        <BarraHistorico title="" match={match}></BarraHistorico>
        {montarGabarito(prova, matrizProva, timer)}
      </div>
    );
  }

  return (
    <div className="container" style={{ position: "relative" }}>
      <BarraHistorico
        title={
          <>
            {prova ? (
              <b>
                {prova.banca.nome} - {prova.orgao.nome} - {prova.nome} -{" "}
                {prova.ano}
              </b>
            ) : (
              ""
            )}
          </>
        }
        match={match}
      />

      <Row>
        {loadingProva || loadingQuestoes ? (
          <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
        ) : (
          <>
            <h3 style={{ marginBottom: 24 }}>
              {prova.banca.nome} - {prova.orgao.nome} - {prova.nome} -{" "}
              {prova.ano}
            </h3>
            {/*<Affix offsetTop={120} style={{ right: 16, position: "absolute" }}>
              <SimuladoInfo>
                <div id="timer">
                  <h1 style={{ margin: 0 }}>{timeString}</h1>
                </div>
                <Progress
                  percent={percent}
                  trailColor="white"
                  style={{ marginBottom: 12 }}
                />
                <Button
                  onClick={() => finalizarProva()}
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Finalizar prova
                </Button>
              </SimuladoInfo>
        </Affix>*/}

            {questoes && questoes.length > 0 ? (
              questoes.map((q) => {
                return (
                  <Questao
                    key={q.id}
                    questao={q}
                    callback={callback}
                    blocked={true}
                  ></Questao>
                );
              })
            ) : (
              <Empty
                description={
                  <>
                    <span>
                      Não há questões disponíveis que atendam a pesquisa!
                    </span>
                    <br />
                    <span>Verifique os filtros e pesquise novamente.</span>
                  </>
                }
                style={{ margin: "64px 0 128px 0" }}
              />
            )}
          </>
        )}
        <div style={{ width: "100%", height: 80 }}></div>
        <Affix
          offsetBottom={0}
          style={{ right: 16, bottom: 0, position: "absolute" }}
        >
          <SimuladoInfo>
            <div id="timer">
              <p className="timer-display">{timeString}</p>
            </div>
            <Progress
              percent={percent}
              trailColor="white"
              style={{ marginBottom: 12 }}
            />
            <Button
              onClick={() => finalizarProva()}
              type="primary"
              style={{ width: "100%" }}
            >
              Finalizar prova
            </Button>
          </SimuladoInfo>
        </Affix>
      </Row>
    </div>
  );
}

export default withRouter(Prova);
