import React, { useEffect } from "react";
import { withRouter } from "react-router";
import BarraHistorico from "../../../components/BarraHistorico";
import RenderHelmet from "../../../components/RenderHelmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Criados from "./Criados";
import Convidados from "./Convidados";
import Respondidos from "./Respondidos";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../../configs/config";

function MeusQuiz({ match }) {
  const login = useSelector((state) => state.login);
  const history = useHistory();

  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/quiz/listar");
  });

  if (!login.isAuthenticate) {
    history.push("/login");
    return false;
  }

  return (
    <div className="container">
      <RenderHelmet
        title={"Cade Direito: Lista de quiz"}
        description="Listagem de quiz criados, quiz respondidos e quiz que foi convidado"
      />
      <BarraHistorico title={"Meus quiz"} match={match} />
      <Criados />
      <Convidados />
      <Respondidos />
    </div>
  );
}

export default withRouter(MeusQuiz);
