import React, { useEffect } from "react";
import { Row, Col, Carousel } from "antd";
import instagram from "../../images/instagram.png";
import {
  faBalanceScale,
  faFileAlt,
  faChartArea,
  faComments,
  faUser,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTelegram,
  faYoutube,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  INSTAGRAM_URL,
  TELEGRAM_URL,
  YOUTUBE_URL,
  FACEBOOK_URL,
} from "../../configs/config";
import { Helmet } from "react-helmet";

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

import devw from "../../images/backgrounds/devices.webp";

import {
  Container,
  BackgroundImage,
  Chamada,
  BtnComece,
  BtnCriarConta,
  Info,
  Conclusao,
  BoxTitulo,
  Social,
  Devices,
  ContainerDevices,
} from "./styles";

function Principal() {
  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/");
  }, []);

  function renderHeader() {
    return (
      <Helmet>
        <title>{`Cadê Direito - Questões de concursos`}</title>
        <meta
          name="description"
          content={`Questões de concurso, questões da área de direito.`}
        ></meta>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
    );
  }

  return (
    <Container>
      {renderHeader()}
      {/** CHAMADA 1 */}
      <Carousel effect="fade" autoplay>
        <div>
          <BackgroundImage className="bg1" />
          <Chamada className="container-fluid">
            <div>
              <div>
                <h1>crie simulados para seus alunos e seguidores</h1>
                <div className="text-center">
                  <BtnComece to={"/quiz/listar"}>Comece já</BtnComece>
                </div>
              </div>
            </div>
          </Chamada>
        </div>
        <div>
          <BackgroundImage className="bg2" />
          <Chamada className="container-fluid">
            <div>
              <div>
                <h1>Estude a qualquer hora e em qualquer lugar</h1>
                <p className="sm-hidden chamada-subtitle">
                  São mais de 15.000 questões de concursos da área de direito.
                </p>
                <div className="text-center">
                  <BtnComece to={"/questoes"}>Comece já</BtnComece>
                </div>
              </div>
            </div>
          </Chamada>
        </div>
      </Carousel>
      {/*<Chamada>
        <div className="backgroundImage bg1" />
        <div className="overlay"></div>
        <div className="text">
          <div>
            <h1>Estude a qualquer hora e em qualquer lugar</h1>
            <p className="chamada-subtitle sm-hidden">
              São mais de 15.000 questões de concursos da área de direito.
            </p>
          </div>
        </div>
      </Chamada>*/}

      {/** INFO */}
      <Info className="container">
        <Row className="mb-5">
          <Col span={24}>
            <p className="mb-0 info-subtitle">
              Tudo que você precisa para se preparar
            </p>
            <h2>você encontra aqui</h2>
          </Col>
        </Row>
        <Row gutter={[24, { sm: 0, md: 48 }]} className="text-left">
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faFileAlt} className="icon" />
              <p className="strong mb-0 ml-2 info-title"> Provas e simulados</p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                Crie, publique e envie simulados para seus alunos e seguidores.
              </p>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faBalanceScale} className="icon" />
              <p className="strong mb-0 ml-2 info-title">
                Questões de concursos
              </p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                São mais de 15.000 questões de concursos selecionadas focadas na
                área de direito.
              </p>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <p className="strong mb-0 ml-2 info-title">
                {" "}
                Encontre as questões ideais
              </p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                Utilize os diversos filtros e encontre as questões da
                disciplina, assunto, concurso, órgão, banca, ano ou local de sua
                preferência.
              </p>
            </div>
          </Col>

          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faChartArea} className="icon" />
              <p className="strong mb-0 ml-2 info-title"> Estatísticas</p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                Visualize o seu desempenho, informações sobre questões e muito
                mais.
              </p>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faComments} className="icon" />
              <p className="strong mb-0 ml-2 info-title"> Comentários</p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                Tire suas dúvidas, deixe sua opinião e contribua com os outros
                usuários na área de comentários das questões.
              </p>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <BoxTitulo>
              <FontAwesomeIcon icon={faUser} className="icon" />
              <p className="strong mb-0 ml-2 info-title">
                {" "}
                Gerencie seu perfil
              </p>
            </BoxTitulo>
            <div className="sm-text-center">
              <p>
                Crie seu perfil e gerencie suas informações pessoais para ter um
                maior controle do seu progresso e uma ter uma melhor expriência.
              </p>
            </div>
          </Col>
        </Row>
      </Info>

      {/** DEVICES */}
      <Devices id="devices">
        <Row className="text-center">
          <Col span={24}>
            <h2 className="mb-0">Acesse de qualquer dispositivo</h2>
            <h3 className="mb-0">
              Nunca foi tão fácil estudar! Aproveite melhor o seu tempo
            </h3>
            <h3>seja pelo seu desktop, notebook, tablet ou smartphone</h3>
          </Col>
        </Row>
        <ContainerDevices className="container">
          <div className="text-center">
            <img src={devw} alt="devices" />
          </div>
        </ContainerDevices>
      </Devices>

      {/** SOCIAL */}
      <Social>
        <Row className="text-center" gutter={[96, 12]}>
          <Col span={24}>
            <h2 className="mb-0 mt-7">Compartilhe</h2>
            <h3 className="mb-0">Utilize as principais redes sociais</h3>
            <h3>e envie para os seus contatos</h3>
            <div className="socialDiv">
              <a
                href={INSTAGRAM_URL}
                rel="noopener noreferrer"
                target="_BLANK"
                aria-label="link-social-instagram"
              >
                <span role="img">
                  <img
                    id="instagram"
                    alt="instagram"
                    src={instagram}
                    className="socialIcon"
                  />
                </span>
              </a>

              <a
                href={TELEGRAM_URL}
                rel="noopener noreferrer"
                target="_BLANK"
                aria-label="link-social-telegram"
              >
                <FontAwesomeIcon
                  id="telegram"
                  icon={faTelegram}
                  className="socialIcon"
                />
              </a>

              <a
                href={YOUTUBE_URL}
                rel="noopener noreferrer"
                target="_BLANK"
                aria-label="link-social-youtube"
              >
                <FontAwesomeIcon
                  id="youtube"
                  icon={faYoutube}
                  className="socialIcon"
                />
              </a>

              <a
                href={FACEBOOK_URL}
                rel="noopener noreferrer"
                target="_BLANK"
                aria-label="link-social-facebook"
              >
                <FontAwesomeIcon
                  id="facebook"
                  icon={faFacebookSquare}
                  className="socialIcon"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Social>

      {/** CONCLUSÃO */}
      <Conclusao>
        <Row gutter={[96, 12]} className="text-center">
          <Col span={24}>
            <h2 className="mb-0 mt-7">Comece a usar!</h2>
            <h3>Para ter uma experiência completa,</h3>
            <div className="mt-5 mb-7">
              <BtnCriarConta to={"/registrar"}>Crie a sua conta</BtnCriarConta>
            </div>
          </Col>
        </Row>
      </Conclusao>
    </Container>
  );
}

export default Principal;
