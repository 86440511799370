const INITIAL_STATE = {
  acao: null,
  id: null,
  titulo: "",
  qtdPorPagina: "5",
  tipo: "múltipla escolha 5",
  expiracao: null,
  questoes: [],
  emailsAdicionados: [],
  visibilidade: "particular",
  ativo: true,
  mostrarResultado: true,
  mostrarGabarito: true,
  emailsNovos: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** STORE */
    case "SET_QUIZ_TITULO": {
      return { ...state, titulo: action.payload.titulo, acao: action.payload.acao };
    }
    case "SET_QUIZ_QTDPORPAGINA": {
      return { ...state, qtdPorPagina: action.payload.qtdPorPagina, acao: action.payload.acao };
    }
    case "SET_QUIZ_TIPO": {
      return { ...state, tipo: action.payload.tipo , acao: action.payload.acao };
    }
    case "SET_QUIZ_EXPIRACAO": {
      return { ...state, expiracao: action.payload.expiracao, acao: action.payload.acao };
    }
    // Questoes
    case "SET_QUIZ_QUESTAO": {
      return { ...state, questoes: [...state.questoes, action.payload ]}
    }
    case "EDIT_QUIZ_QUESTAO": {
      var questoes = state.questoes;
      questoes[action.payload.position] = action.payload.opts;
      return { ...state, questoes}
    }
    case "SET_QUIZ_QUESTOES": {
      return { ...state, questoes: action.payload}
    }
    // publicacao
    case "SET_QUIZ_VISIBILIDADE": {
      return { ...state, visibilidade: action.payload };
    }
    case "SET_QUIZ_ATIVO": {
      return { ...state, ativo: action.payload };
    }
    case "SET_QUIZ_MOSTRARRESULTADO": {
      return { ...state, mostrarResultado: action.payload}
    }
    case "SET_QUIZ_MOSTRARGABARITO": {
      return { ...state, mostrarGabarito: action.payload}
    }
    case "SET_QUIZ_EMAILNOVO": {
      return {...state, emailsNovos: [...state.emailsNovos, action.payload]}
    }
    case "UPDATE_QUIZ_EMAILSNOVOS": {
      return {...state, emailsNovos: action.payload}
    }
    case "UPDATE_QUIZ_EMAILSADICIONADOS": {
      return {...state, emailsAdicionados: action.payload}
    }
    // resetar quiz
    case "SET_QUIZ_RESET": {
      return INITIAL_STATE
    }
    // INICIAR EDITAR SIMULADO
    case "INIT_QUIZ_EDIT": {
      return {
        acao: 'edição',
        ...action.payload
      }
    }

    /** DEFAULT */
    default: {
      return state;
    }
  }
}
