import React, { Component } from 'react'
import style from './style.module.css'
import { Row, Avatar, Col, Form, Input, Button, Skeleton, Empty } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { bindActionCreators } from 'redux'
import { getUsuario, alterarSenha } from '../../actions/Usuario.action'
import { connect } from 'react-redux'

class UsuarioSenha extends Component {

  state = {
    salvo: false
  }

  componentDidMount = () => {
    const { accessToken, usuario } = this.props.login
    if (usuario && accessToken) {
      this.props.getUsuario(usuario.id, accessToken)
    }
  }

  onFinish = (values) => {
    const { accessToken } = this.props.login
    this.props.alterarSenha(accessToken, values)
    this.setState({ 'salvo': true })
  }

  setSalvo = () => {
    setTimeout(() => {
      this.setState({ 'salvo': false })
    }, 3000)
  }

  render() {
    const { loadUsuarios, usuario, salvando } = this.props.usuarios
    const { salvo } = this.state

    if (salvo && !salvando) {
      this.setSalvo()
    }

    return (
      <>
        {
          usuario
            ? (
              <>
                <Row gutter={[24, 48]}>
                  <Col flex="96px">
                    <Avatar size={72} icon={<UserOutlined />} />
                  </Col>
                </Row>

                <div className={style.form}>
                  <Form name="dados" onFinish={this.onFinish} initialValues={{
                    tratamento: usuario.tratamento,
                    //nascimento: usuario.nascimento,
                    site: usuario.site,
                    telefone: usuario.telefone
                  }} labelCol={{ span: 4 }}
                    wrapperCol={16}>

                    <div className={style.container}>
                      <h4 className="strong">Senha</h4>
                      <Form.Item
                        label="Senha atual"
                        name="senhaatual"
                        rules={[{ message: 'Preencha o nome!' }]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label="Nova senha"
                        name="novasenha"
                        rules={[{ message: 'Preencha o nome!' }]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label="Confirmar senha"
                        name="confirmarsenha"
                        rules={[
                          {
                            required: true,
                            message: 'Confirme a senha!',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('novasenha') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('Senhas diferentes!');
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>

                    <Col md={{ offset: 4 }}>
                      <Form.Item>
                        {
                          salvando
                            ? <Button disabled type="primary" size="large">Alterando a senha...</Button>
                            : (
                              salvo
                                ? <Button style={{backgroundColor: '#52c41a', color: 'white'}} size="large">Senha alterada!</Button>
                                : <Button type="primary" htmlType="submit" size="large">Alterar a senha</Button>
                              
                            )
                        }
                      </Form.Item>
                    </Col>

                  </Form>
                </div>
              </>
            )
            : (
              !loadUsuarios
                ? <Empty description="Dados do Usuário não encontrados" style={{ margin: '64px 0 128px 0' }} />
                : <Skeleton active paragraph={{ rows: 20 }}></Skeleton>
            )
        }
      </>
    )
  }
}

const mapStateToProps = state =>
  ({
    login: state.login,
    usuarios: state.usuarios
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUsuario, alterarSenha }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioSenha)