const INITIAL_STATE = {
  origin: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "PERSISTORIGIN_SUCCESS": {
      return {
        ...state,
        origin: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
