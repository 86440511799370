import React, { Component } from 'react'
import { Col, Row, Skeleton, Empty } from 'antd';
import style from './style.module.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getConcursos } from '../../actions/Concursos.action'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import BarraHistorico from '../../components/BarraHistorico'
import { withRouter } from "react-router"
import RenderHelmet from '../../components/RenderHelmet'

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

class Concursos extends Component {

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/concursos");
    window.scrollTo(0, 0)
    this.props.getConcursos()
  }

  renderLoading = () => {
    return (
      <>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
      </>
    )
  }

  renderConcurso = (concurso) => {
    return (
      <Col key={concurso.id} style={{marginBottom: 48, width: '100%'}} sm={{ span: 24 }} md={{ span: 12 }}>
        <div className={style.boxConcurso}>
          <h3 style={{marginBottom: 0}}>{concurso.nome}  - {concurso.ano}</h3>

          <Link style={{marginBottom: 12, display: 'block'}} to={`/concursos/${concurso.id}`}><LinkOutlined /> Ver mais detalhes</Link>
          
          <p className="no-margin">
            <span className="strong no-margin">Banca: </span>
            <Link to={`/questoes?bancas[]=${concurso.banca.id}`}><LinkOutlined /> {concurso.banca.nome}</Link>
          </p>

          <p className="no-margin">
            <span className="strong no-margin">Órgão: </span>
            <Link to={`/questoes?orgaos[]=${concurso.orgao.id}`}><LinkOutlined /> {concurso.orgao.nome}</Link>
          </p>

          <p className="no-margin">
            <span className="strong no-margin">Total de: </span>
            <Link to={`/questoes?concursos[]=${concurso.id}`}><LinkOutlined /> {concurso.questoes.length} questões</Link>
          </p>

        </div>
      </Col>
    )
  }

  renderConcursos = (concursos) => {
    return concursos.map(concurso => {
      return this.renderConcurso(concurso)
    })
  }

  render() {
    const { loadConcursos, concursos } = this.props.concursos

    return (
      <div className="container">
        <RenderHelmet title={'Cade Direito: Concursos'} description={'Lista de concursos na área de direito'}/>
        <BarraHistorico title={'Concursos'} match={ this.props.match }/>
        <Row gutter={[24,24]}>
          {
            concursos
              ? this.renderConcursos(concursos)
              : (
                !loadConcursos
                  ? <Empty description="Não há concursos disponíveis" style={{margin: '64px 0 128px 0'}} />
                  : <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
                )
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    concursos: state.concursos
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getConcursos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Concursos))