import { API_URL } from "../configs/config";

export const getComentarios = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_COMENTARIOS_REQUEST" });
    fetch(`${API_URL}/questao/${id}/comentario/aprovados`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_COMENTARIOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_COMENTARIOS_FAILURE",
          payload: error,
        })
      );
  };
};
