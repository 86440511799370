import React, { useState, useEffect } from "react";
import { API_URL } from "../../../../configs/config";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  Col,
  Row,
  Table,
  Tag,
  Button,
  Tooltip,
  Descriptions,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import ModalGabarito from "../modalGabarito";
import { ProfileOutlined } from "@ant-design/icons";
import style from "./style.module.css";

function Participando() {
  //Selectors
  const login = useSelector((state) => state.login);

  //States
  const [loadSimulados, setLoadSimulados] = useState(false);
  const [loadingSimulados, setLoadingSimulados] = useState(false);
  const [simuladoSelecionado, setSimuladoSelecionado] = useState({});
  const [respondidos, setRespondidos] = useState([]);
  const [showModalGabarito, setShowModalGabarito] = useState(false);
  const [gabarito, setGabarito] = useState(null);

  // Effects
  useEffect(() => {
    async function getSimulados(login) {
      setLoadingSimulados(true);
      let headers = new Headers({
        Authorization: `Basic ${login.accessToken}`,
        "Content-Type": "application/json",
      });
      const _respondidosResponse = await fetch(
        `${API_URL}/simulados/respondidos`,
        {
          method: "get",
          headers,
        }
      );
      const respondidosResponse = await _respondidosResponse.json();
      setRespondidos(respondidosResponse.data);
      setLoadSimulados(true);
      setLoadingSimulados(false);
    }
    getSimulados(login);
  }, [login]);

  async function getGabarito(idSimulado) {
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const _gabaritoResponse = await fetch(
      `${API_URL}/simulados/${idSimulado}/gabarito`,
      {
        method: "get",
        headers,
      }
    );
    const gabaritoResponse = await _gabaritoResponse.json();
    setGabarito(gabaritoResponse.data);
    setShowModalGabarito(true);
  }

  const columnsRespondidos = [
    {
      title: "Titulo",
      dataIndex: "simulado",
      key: "1",
      render: (simulado) => {
        const current_time = Math.floor(Date.now() / 1000);
        const expire_time = moment(simulado.expire_at).format("X");
        if (simulado.ativo === "FALSE" || expire_time <= current_time) {
          return simulado.titulo;
        } else {
          return <Link to={`/quiz/${simulado.hash}`}>{simulado.titulo}</Link>;
        }
      },
    },
    {
      title: "Criador",
      dataIndex: "simulado",
      key: "criador",
      width: 120,
      render: (simulado) => {
        return <span>{simulado.dono.usuario}</span>;
      },
    },
    {
      title: "Ativo",
      dataIndex: "simulado",
      key: "ativo",
      width: 60,
      className: "d-none d-md-table-cell",
      render: (simulado) => {
        return (
          <Tag
            style={{ width: 50, textAlign: "center" }}
            color={simulado.ativo === "TRUE" ? "blue" : "red"}
          >
            {simulado.ativo === "TRUE" ? "ativo" : "inativo"}
          </Tag>
        );
      },
    },
    {
      title: "Respondido",
      dataIndex: "simulado",
      key: "ativo",
      width: 120,
      defaultSortOrder: "descend",
      className: "d-none d-md-table-cell",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (simulado, respondido) => moment(respondido.created_at).fromNow(),
    },
    {
      title: "Criação",
      dataIndex: "simulado",
      key: "created_at",
      width: 120,
      defaultSortOrder: "descend",
      className: "d-none d-md-table-cell",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (simulado) => moment(simulado.created_at).fromNow(),
    },
    {
      title: "Expiração",
      dataIndex: "simulado",
      key: "expire_at",
      className: "d-none d-md-table-cell",
      width: 120,
      sorter: (a, b) =>
        moment(a.expire_at).format("X") - moment(b.expire_at).format("X"),
      render: (simulado) => {
        if (!simulado.expire_at) {
          return null;
        } else {
          const current_time = Math.floor(Date.now() / 1000);
          const expire_time = moment(simulado.expire_at).format("X");
          return expire_time > current_time ? (
            moment(simulado.expire_at).fromNow()
          ) : (
            <Tag color={"red"}>expirado</Tag>
          );
        }
      },
    },
    {
      title: "Ações",
      dataIndex: "simulado",
      key: "",
      width: 120,
      render: (simulado, respondido) => {
        return (
          <>
            {simulado.mostrargabarito === "TRUE" ? (
              <Tooltip title="Ver gabarito">
                <Button
                  size="small"
                  onClick={() => {
                    getGabarito(simulado.id);
                    setSimuladoSelecionado(simulado);
                    return true;
                  }}
                >
                  <ProfileOutlined />
                  <span className="d-none d-md-inline-block">
                    {" "}
                    Ver gabarito
                  </span>
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {loadSimulados && !loadingSimulados ? (
            <>
              <h5>Quiz respondidos</h5>
              <Table
                rowKey="id"
                columns={columnsRespondidos}
                dataSource={respondidos}
                size="small"
                pagination={{ pageSize: 5 }}
                expandable={{
                  expandedRowRender: (simulado) => {
                    return (
                      <div style={{ marginBottom: 48 }}>
                        <Descriptions
                          size="small"
                          column={1}
                          style={{ marginBottom: 48 }}
                        >
                          <Descriptions.Item label="Criador">
                            {simulado.simulado.dono.usuario}
                          </Descriptions.Item>
                          <Descriptions.Item label="Respondido">
                            {moment(simulado.created_at).format(
                              "DD/MM/YYYY H:mm:ss"
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Ativo"
                            className={style.switch}
                          >
                            <Switch
                              size="small"
                              disabled
                              checked={
                                simulado.simulado.ativo === "TRUE"
                                  ? true
                                  : false
                              }
                            />
                          </Descriptions.Item>
                          <Descriptions.Item label="Visibilidade">
                            {simulado.simulado.visibilidade}
                          </Descriptions.Item>
                          <Descriptions.Item label="Tipo">
                            {simulado.simulado.tipo}
                          </Descriptions.Item>
                          <Descriptions.Item label="Criador">
                            {simulado.simulado.dono.nome}
                          </Descriptions.Item>
                          <Descriptions.Item label="Data de criação">
                            {moment(simulado.simulado.created_at).format(
                              "DD/MM/YYYY H:mm:ss"
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="Data de expiração">
                            {moment(simulado.simulado.expire_at).format(
                              "DD/MM/YYYY H:mm:ss"
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    );
                  },
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {!showModalGabarito || (
        <ModalGabarito
          onOk={(success) => {
            setShowModalGabarito(false);
          }}
          gabarito={gabarito}
          simulado={simuladoSelecionado}
          onCancel={() => setShowModalGabarito(false)}
        />
      )}
    </>
  );
}

export default withRouter(Participando);
