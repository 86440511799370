import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Tabs, Modal, Button, Space, Spin } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { initEditQuiz } from "../../../actions/Quiz.action";

import DadosQuiz from "./dados";
import QuestoesQuiz from "./questoes";
import PublicacaoQuiz from "./publicacao";
import Publicado from "./publicado";
import Preview from "../Preview";
import { Container, ContainerLoading } from "./styles";
import { API_URL } from "../../../configs/config";

const { TabPane } = Tabs;

function EditarQuiz(props) {
  const dispatch = useDispatch();

  var login = useSelector((state) => state.login);

  const [activeKey, setActiveKey] = useState("#1");
  const [publicado, setPublicado] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [load, setLoad] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);

  const [questoesOriginais, setQuestoesOriginais] = useState([]);
  const [emailsAdicionadosOriginais, setEmailsAdicionadosOriginais] = useState(
    []
  );

  const {
    titulo,
    qtdPorPagina,
    tipo,
    expiracao,
    visibilidade,
    ativo,
    mostrarResultado,
    mostrarGabarito,
    questoes,
    emailsAdicionados,
  } = useSelector((state) => {
    return state.quiz;
  });

  const { match } = props;

  useEffect(
    function () {
      window.scrollTo(0, 0);
      async function getQuiz(id) {
        // setLoading(true);
        let headers = new Headers({
          Authorization: `Basic ${login.accessToken}`,
          "Content-Type": "application/json",
        });
        const quizResponse = await fetch(`${API_URL}/simulados/${id}`, {
          method: "get",
          headers,
        });
        const _quizResponse = await quizResponse.json();

        const questoesResponse = await fetch(
          `${API_URL}/simulados/${id}/questao`,
          {
            method: "get",
            headers,
          }
        );
        const _questoesResponse = await questoesResponse.json();

        const emailsAdicionadosResponse = await fetch(
          `${API_URL}/simulados/${id}/usuarios`,
          {
            method: "get",
            headers,
          }
        );
        const _emailsAdicionadosResponse =
          await emailsAdicionadosResponse.json();

        const data = {
          id: _quizResponse.data.id,
          titulo: _quizResponse.data.titulo,
          qtdPorPagina: _quizResponse.data.questoesporpagina,
          tipo: _quizResponse.data.tipo,
          expiracao: _quizResponse.data.expire_at,
          visibilidade: _quizResponse.data.visibilidade,
          ativo: _quizResponse.data.ativo === "TRUE" ? true : false,
          mostrarResultado:
            _quizResponse.data.mostrarresultado === "TRUE" ? true : false,
          mostrarGabarito:
            _quizResponse.data.mostrargabarito === "TRUE" ? true : false,
          emailsNovos: [],
        };

        const questoes = [];
        _questoesResponse.data.map((q) => {
          if (!q.deleted_at) {
            questoes.push({
              id: q.id,
              enunciado: q.enunciado,
              respostas: q.simulado_questao_respostas,
              opcaoCorreta: "" + q.opcaoCorreta,
            });
          }
          return true;
        });
        const emailsAdicionados = [];
        _emailsAdicionadosResponse.data.map((e) => {
          if (!e.deleted_at) {
            emailsAdicionados.push({
              id: e.id,
              email: e.email,
            });
          }
          return true;
        });
        setQuestoesOriginais(questoes);
        setEmailsAdicionadosOriginais(emailsAdicionados);
        data.questoes = questoes;
        data.emailsAdicionados = emailsAdicionados;
        dispatch(initEditQuiz(data));
        setLoad(true);
        // setLoading(false);
      }
      if (match.params && match.params.id) {
        getQuiz(match.params.id);
      } else {
        // setLoading(false);
      }
    },
    [dispatch, login, match]
  );

  function quizPublicado(_quiz) {
    setQuiz(_quiz);
    setPublicado(true);
  }

  function visualizar() {
    setPreviewVisible(true);
  }

  function closePreviewModal() {
    setPreviewVisible(false);
  }

  if (!load) {
    return (
      <ContainerLoading>
        <Space size="middle">
          <Spin tip="Carregando quiz..." size="large" />
        </Space>
      </ContainerLoading>
    );
  }

  return (
    <Container className="container">
      <h3 className="mt-4">Editar quiz</h3>
      {!publicado ? (
        <Tabs
          activeKey={activeKey}
          type="card"
          size="large"
          onChange={(value) => setActiveKey(value)}
          tabBarExtraContent={
            <Button type="text" onClick={visualizar}>
              <EyeOutlined /> Visualizar
            </Button>
          }
        >
          <TabPane tab="Dados do quiz" key="#1">
            <DadosQuiz
              titulo={titulo}
              qtdPorPagina={qtdPorPagina}
              tipo={tipo}
              expiracao={expiracao}
            ></DadosQuiz>
          </TabPane>
          <TabPane tab="Questões" key="#2">
            <QuestoesQuiz questoesSelector={questoes}></QuestoesQuiz>
          </TabPane>
          <TabPane tab="Publicação" key="#3">
            <PublicacaoQuiz
              idQuiz={match.params.id}
              quizPublicado={(_quiz) => {
                quizPublicado(_quiz);
              }}
              titulo={titulo}
              qtdPorPagina={qtdPorPagina}
              tipo={tipo}
              expiracao={expiracao}
              visibilidade={visibilidade}
              ativo={ativo}
              mostrarResultado={mostrarResultado}
              mostrarGabarito={mostrarGabarito}
              emailsNovos={null}
              emailsAdicionados={emailsAdicionados}
              emailsAdicionadosOriginais={emailsAdicionadosOriginais}
              questoes={questoes}
              questoesOriginais={questoesOriginais}
            ></PublicacaoQuiz>
          </TabPane>
        </Tabs>
      ) : (
        <Publicado quiz={quiz} />
      )}
      <Modal
        title={[
          <EyeOutlined />,
          <span className="ml-2">Pré-visualização do quiz</span>,
        ]}
        visible={previewVisible}
        onOk={() => closePreviewModal()}
        onCancel={() => closePreviewModal()}
        footer={null}
        style={{ top: 10, paddingBottom: 0 }}
        width={"100%"}
        bodyStyle={{
          height: "calc(100vh - 95px)",
          overflow: "scroll",
        }}
      >
        <Preview />
      </Modal>
    </Container>
  );
}

export default withRouter(EditarQuiz);
