import React, { useEffect, useState } from "react";
import UsuarioDados from "./dados";
import UsuarioSenha from "./senha";
import UsuarioEstatistica from "./estatisticas";
import { Layout, Menu } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  BarChartOutlined,
  LockOutlined,
} from "@ant-design/icons";
import style from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import { deslogar } from "../../actions/Login.action";
import RenderHelmet from "../../components/RenderHelmet";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

const { Content, Sider } = Layout;

function MenuUsuario(e) {
  const dispatch = useDispatch();

  //Selectors
  const login = useSelector((state) => state.login);

  //States
  const [menu, setMenu] = useState(1);

  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/usuario");
  }, []);

  //Functions
  function renderContent() {
    switch (menu) {
      case 1: {
        return <UsuarioEstatistica />;
      }
      case 2: {
        return <UsuarioDados />;
      }
      case 3: {
        return <UsuarioSenha />;
      }
      default: {
        return <UsuarioDados />;
      }
    }
  }

  function renderMenuLateral() {
    const { accessToken } = login;
    return (
      <Sider style={{ backgroundColor: "white" }} className={style.sider}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{ height: "100%" }}
        >
          <Menu.Item key="1" onClick={() => setMenu(1)}>
            <BarChartOutlined />
            <label>Estatísticas</label>
          </Menu.Item>
          <Menu.Item key="2" onClick={() => setMenu(2)}>
            <UserOutlined />
            <label>Dados Pessoais</label>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => setMenu(3)}>
            <LockOutlined />
            <label>Alterar senha</label>
          </Menu.Item>
          <Menu.Item onClick={() => dispatch(deslogar(accessToken))}>
            <LogoutOutlined />
            <label>Sair</label>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }

  //Render
  return (
    <>
      <RenderHelmet
        title={"Cade Direito: Usuário"}
        description="Configurações do usuário"
      />
      {login.isAuthenticate ? (
        <div className="container">
          <Layout className={style.layout}>
            {renderMenuLateral()}
            <Content className={style.usuarioContent}>
              {renderContent()}
            </Content>
          </Layout>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default MenuUsuario;
