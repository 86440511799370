import React, { Component } from "react";
import style from "./style.module.css";
import { Form, Input, Button, Col, Row, Result } from "antd";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_ID } from "../../configs/config";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

export default class RecuperarSenha extends Component {
  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/recuperar");
    window.scrollTo(0, 0);
  };

  state = {
    loading: false,
    status: null,
  };

  recaptcha = null;
  values = [];

  onFinish = (values) => {
    this.values = values;
    this.recaptcha.execute();
  };

  onResolved = () => {
    const opts = {
      email: this.values.email,
      recaptchaKey: this.recaptcha.getResponse(),
    };
    this.callbackRecuperar(opts);
  };

  callbackRecuperar = (opts) => {
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    this.setState({ loading: true });
    fetch(`https://api.cadedireito.com.br/v1/recuperar`, {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r === "sucesso") {
          this.setState({ loading: false, status: "sucesso" });
        } else {
          this.setState({ loading: false, status: "erro", contatoError: true });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro", contatoError: true });
      });
  };

  renderSucesso() {
    return (
      <Result
        status="success"
        style={{ margin: "96px 0" }}
        title="Uma mensagem foi enviada com o link para mudar a senha ao e-mail informado caso esteja em nossa base de dados!"
      />
    );
  }

  renderErro() {
    return (
      <Result
        status="error"
        style={{ margin: "96px 0" }}
        title="Erro ao tentar enviar o e-mail."
      />
    );
  }

  render() {
    const { status } = this.state;

    if (status === "sucesso" || status === "erro") {
      return this.renderSucesso();
    }

    /*if (status === 'erro') { 
      return this.renderErro()
    }*/

    return (
      <>
        <div style={{ margin: "48px auto" }} className="container">
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div style={{ textAlign: "center", padding: "8px 48px" }}>
                <h1 style={{ color: "var(--primary)" }}>
                  Recuperação de senha
                </h1>
                <p>
                  Um e-mail de recuperação de senha será enviado caso ele esteja
                  em nosso banco de dados.
                </p>
              </div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} style={{ width: "100%" }}>
              <Form
                className={style.formRecuperar}
                name="recuperacao"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <span>E-mail</span>
                <Form.Item
                  name="email"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      message: "O E-mail informado não é válido!",
                    },
                    {
                      required: true,
                      message: "Por favor, informe o seu E-mail!",
                    },
                  ]}
                >
                  <Input placeholder="Informe o e-mail" />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={this.state.loading}
                  >
                    {!this.state.loading ? "Recuperar" : "Recuperando..."}
                  </Button>
                </Form.Item>
                <Recaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={RECAPTCHA_ID}
                  onResolved={this.onResolved}
                />
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
