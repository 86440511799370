import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Input, Row, Col, Radio, Button, Form, message } from "antd";
import { editQuestao } from "../../../actions/Quiz.action";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function ModalEditarQuestao({ position, questao, onOk, onCancel, tipo }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [enunciado, setEnunciado] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const contentBlock = htmlToDraft(questao.enunciado);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEnunciado(editorState);
  }, [questao]);

  function onFinish(values) {
    setLoading(true);
    var respostas = [];
    if (tipo !== "certo ou errado") {
      respostas = [
        { texto: values.respostaA },
        { texto: values.respostaB },
        { texto: values.respostaC },
        { texto: values.respostaD },
      ];
      if (tipo === "múltipla escolha 5") {
        respostas[4] = { texto: values.respostaE };
      }
    } else {
      respostas = [{ texto: "Certo" }, { texto: "Errado" }];
    }
    let opts = {
      respostas: respostas,
      opcaoCorreta: values.respostaCorreta,
      feedback: values.feedback,
      enunciado: draftToHtml(convertToRaw(enunciado.getCurrentContent())),
    };
    dispatch(editQuestao(position, opts));
    setLoading(false);
    message.success("Questão editada com sucesso!");
    onOk(true);
    form.resetFields();
  }

  return (
    <Modal
      title="Editar questão"
      visible={"true"}
      onOk={onOk}
      width={"100%"}
      onCancel={onCancel}
      footer={[]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          respostaA: questao.respostas[0].texto,
          respostaB: questao.respostas[1].texto,
          respostaC: questao.respostas[2].texto,
          respostaD: questao.respostas[3].texto,
          respostaE: questao.respostas[4].texto,
          respostaCorreta: questao.opcaoCorreta,
          enunciado: questao.enunciado,
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={tipo !== "certo ou errado" ? 12 : 24}>
            <div>
              <span>Enunciado</span>
              <Form.Item
                name="enunciado"
                rules={[{ required: true, message: "Escreva o enunciado!" }]}
              >
                <Editor
                  handlePastedText={() => false}
                  editorState={enunciado}
                  onEditorStateChange={(state) => setEnunciado(state)}
                  toolbarClassName="wysiwygToolbar"
                  wrapperClassName="wysiwygTexto"
                  editorClassName="wysiwygEditor"
                  toolbar={{
                    options: ["inline"],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"],
                    },
                  }}
                />
              </Form.Item>
              {/*<Input placeholder="Digite aqui o enunciado"></Input>*/}
            </div>
          </Col>
          {tipo !== "certo ou errado" ? (
            <Col span={24} md={12}>
              <div className="mb-3">
                <span>Resposta A</span>
                <Form.Item
                  name="respostaA"
                  rules={[{ required: true, message: "Informe a resposta!" }]}
                >
                  <Input placeholder="Digite aqui a resposta"></Input>
                </Form.Item>
              </div>

              <div className="mb-3">
                <span>Resposta B</span>
                <Form.Item
                  name="respostaB"
                  rules={[{ required: true, message: "Informe a resposta!" }]}
                >
                  <Input placeholder="Digite aqui a resposta"></Input>
                </Form.Item>
              </div>

              <div className="mb-3">
                <span>Resposta C</span>
                <Form.Item
                  name="respostaC"
                  rules={[{ required: true, message: "Informe a resposta!" }]}
                >
                  <Input placeholder="Digite aqui a resposta"></Input>
                </Form.Item>
              </div>

              <div className="mb-3">
                <span>Resposta D</span>
                <Form.Item
                  name="respostaD"
                  rules={[{ required: true, message: "Informe a resposta!" }]}
                >
                  <Input placeholder="Digite aqui a resposta"></Input>
                </Form.Item>
              </div>

              {tipo === "múltipla escolha 5" && (
                <div className="mb-3">
                  <span>Resposta E</span>
                  <Form.Item
                    name="respostaE"
                    rules={[{ required: true, message: "Informe a resposta!" }]}
                  >
                    <Input placeholder="Digite aqui a resposta"></Input>
                  </Form.Item>
                </div>
              )}
            </Col>
          ) : (
            <></>
          )}
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <div>
                <span>Resposta correta</span>
              </div>
              <div>
                <Form.Item
                  name="respostaCorreta"
                  rules={[
                    { required: true, message: "Escolha a resposta correta!" },
                  ]}
                >
                  {tipo !== "certo ou errado" ? (
                    <Radio.Group>
                      <Radio.Button value="1">A</Radio.Button>
                      <Radio.Button value="2">B</Radio.Button>
                      <Radio.Button value="3">C</Radio.Button>
                      <Radio.Button value="4">D</Radio.Button>
                      {tipo === "múltipla escolha 5" && (
                        <Radio.Button value="5">E</Radio.Button>
                      )}
                    </Radio.Group>
                  ) : (
                    <Radio.Group>
                      <Radio.Button value="1">Certo</Radio.Button>
                      <Radio.Button value="2">Errado</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button key="cancelar" onClick={onCancel}>
              Cancelar
            </Button>{" "}
            <Button
              htmlType="submit"
              key="criar"
              type="primary"
              name="submit"
              value="A"
              loading={loading}
            >
              Editar
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalEditarQuestao;
