import { API_URL } from "../configs/config";

export const getUsuario = (id, accessToken) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });

  return (dispatch) => {
    dispatch({ type: "GET_USUARIO_REQUEST" });
    fetch(`${API_URL}/usuario/${id}`, { method: "get", headers })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_USUARIO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_USUARIO_FAILURE",
          payload: error,
        })
      );
  };
};

export const updateUsuario = (accessToken, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(opts);
  return (dispatch) => {
    dispatch({ type: "UPDATE_USUARIO_REQUEST" });
    fetch(`${API_URL}/me/usuario`, { method: "put", headers, body })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "UPDATE_USUARIO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "UPDATE_USUARIO_FAILURE",
          payload: error,
        })
      );
  };
};

export const alterarSenha = (accessToken, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(opts);
  return (dispatch) => {
    dispatch({ type: "UPDATE_USUARIO_REQUEST" });
    fetch(`${API_URL}/me/senha`, { method: "put", headers, body })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "UPDATE_USUARIO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "UPDATE_USUARIO_FAILURE",
          payload: error,
        })
      );
  };
};
