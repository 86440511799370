import React, { Component } from "react";
import style from "./style.module.css";
import {
  Row,
  Avatar,
  Col,
  Form,
  Input,
  Button,
  Skeleton,
  DatePicker,
  Empty,
  Select,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { getUsuario, updateUsuario } from "../../actions/Usuario.action";
import { connect } from "react-redux";
import moment from "moment";
import MaskedInput from "antd-mask-input";

const { Option } = Select;

class UsuarioDados extends Component {
  state = {
    telefone: null,
    nascimento: null,
    salvo: false,
    perfil: null,
  };

  componentDidMount = () => {
    const { accessToken, usuario } = this.props.login;
    if (usuario && accessToken) {
      this.props.getUsuario(usuario.id, accessToken);
    }
    this.setState({ perfil: usuario.perfil });
  };

  onFinish = (values) => {
    var { telefone, nascimento, perfil } = this.state;
    if (!telefone) {
      telefone = this.props.usuarios.usuario.telefone;
    }
    if (!nascimento) {
      nascimento = this.props.usuarios.usuario.nascimento;
    }
    if (!perfil) {
      perfil = this.props.usuarios.usuario.perfil;
    }
    values["telefone"] = telefone;
    values["perfil"] = perfil;

    const opts = {
      perfil: perfil,
      nascimento: nascimento ? moment(nascimento).format("YYYY-MM-DD") : null,
      telefone: telefone,
      tratamento: values["tratamento"] ? values["tratamento"] : null,
      site: values["site"] ? values["site"] : null,
      instagram: values["instagram"] ? values["instagram"] : null,
      telegram: values["telegram"] ? values["telegram"] : null,
      facebook: values["facebook"] ? values["facebook"] : null,
      pais: values["pais"] ? values["pais"] : null,
      estado: values["estado"] ? values["estado"] : null,
      cidade: values["cidade"] ? values["cidade"] : null,
      endereco: values["endereco"] ? values["endereco"] : null,
    };
    const { accessToken } = this.props.login;
    this.props.updateUsuario(accessToken, opts);
    this.setState({ salvo: true });
  };

  setSalvo = () => {
    setTimeout(() => {
      this.setState({ salvo: false });
    }, 3000);
  };

  render() {
    const { loadUsuarios, salvando, usuario } = this.props.usuarios;
    const { salvo, perfil } = this.state;

    if (salvo && !salvando) {
      this.setSalvo();
    }

    return (
      <>
        {usuario ? (
          <>
            <Row gutter={[24, 48]}>
              <Col span={"auto"} flex="96px">
                <Avatar size={72} icon={<UserOutlined />} />
              </Col>
            </Row>

            <div className={style.form}>
              <Form
                name="dados"
                onFinish={this.onFinish}
                initialValues={{
                  tratamento: usuario.tratamento,
                  site: usuario.site,
                  instagram: usuario.instagram,
                  telegram: usuario.telegram,
                  facebook: usuario.facebook,
                  pais: usuario.pais,
                  estado: usuario.estado,
                  cidade: usuario.cidade,
                  endereco: usuario.endereco,
                }}
                labelCol={{ span: 4 }}
                wrapperCol={16}
              >
                <div className={style.container}>
                  <h4 className="strong">Dados cadastrais</h4>
                  <Form.Item label="Usuário">
                    <span style={{ padding: "0 12px" }}>{usuario.usuario}</span>
                  </Form.Item>
                  <Form.Item label="Nome">
                    <span style={{ padding: "0 12px" }}>{usuario.nome}</span>
                  </Form.Item>
                  <Form.Item label="E-mail">
                    <span style={{ padding: "0 12px" }}>{usuario.email}</span>
                  </Form.Item>

                  <Form.Item label="Data de criação">
                    <span style={{ padding: "0 12px" }}>
                      {usuario.created_at
                        ? moment(usuario.created_at).fromNow()
                        : null}
                    </span>
                  </Form.Item>

                  <Form.Item label="Perfil">
                    <Select
                      allowClear
                      name="perfil"
                      value={perfil}
                      placeholder="Selecione um perfil"
                      onChange={(value) => this.setState({ perfil: value })}
                    >
                      <Option value={"ESTUDANTE"} key={1}>
                        Estudante
                      </Option>
                      <Option value={"PROFESSOR"} key={2}>
                        Professor
                      </Option>
                      <Option value={"PROFISSIONAL"} key={3}>
                        Profissional de Direito
                      </Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className={style.container}>
                  <h4 className="strong">Dados pessoais</h4>
                  <Form.Item
                    label="Tratamento"
                    name="tratamento"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Data de nasc.">
                    <DatePicker
                      defaultValue={
                        usuario.nascimento
                          ? moment(usuario.nascimento, "YYYY-MM-DD")
                          : null
                      }
                      format={"DD/MM/YYYY"}
                      onChange={(v) => this.setState({ nascimento: v._i })}
                    />
                  </Form.Item>
                </div>

                <div className={style.container}>
                  <h4 className="strong">Contato</h4>
                  <Form.Item
                    label="Página pessoal"
                    name="site"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Telefone">
                    <MaskedInput
                      defaultValue={usuario.telefone ? usuario.telefone : ""}
                      mask="+11 (11) 11111-1111"
                      onChange={(v) =>
                        this.setState({ telefone: v.target.value })
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Instagram"
                    name="instagram"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Telegram"
                    name="telegram"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Facebook"
                    name="facebook"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className={style.container}>
                  <h4 className="strong">Endereço</h4>
                  <Form.Item
                    label="País"
                    name="pais"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Estado"
                    name="estado"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Cidade"
                    name="cidade"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Endereço"
                    name="endereco"
                    rules={[{ message: "Preencha o nome!" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <Col md={{ offset: 4 }}>
                  <Form.Item>
                    {salvando ? (
                      <Button disabled type="primary" size="large">
                        Salvando...
                      </Button>
                    ) : salvo ? (
                      <Button
                        style={{ backgroundColor: "#52c41a", color: "white" }}
                        size="large"
                      >
                        Salvo!
                      </Button>
                    ) : (
                      <Button type="primary" htmlType="submit" size="large">
                        Salvar
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Form>
            </div>
          </>
        ) : !loadUsuarios ? (
          <Empty
            description="Dados do Usuário não encontrados"
            style={{ margin: "64px 0 128px 0" }}
          />
        ) : (
          <Skeleton active paragraph={{ rows: 20 }}></Skeleton>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  usuarios: state.usuarios,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsuario, updateUsuario }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioDados);
