export function setAcao(acao) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_ACAO", payload: acao });
  };
}

export function setTitulo(acao, titulo) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_TITULO", payload: { acao, titulo } });
  };
}

export function setQtdPorPagina(acao, qtdPorPagina) {
  return (dispatch) => {
    dispatch({
      type: "SET_QUIZ_QTDPORPAGINA",
      payload: { acao, qtdPorPagina },
    });
  };
}

export function setTipo(acao, tipo) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_TIPO", payload: { acao, tipo } });
  };
}

export function setExpiracao(acao, expiracao) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_EXPIRACAO", payload: { acao, expiracao } });
  };
}

export function setQuestao(opts) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_QUESTAO", payload: opts });
  };
}

export function editQuestao(position, opts) {
  return (dispatch) => {
    dispatch({ type: "EDIT_QUIZ_QUESTAO", payload: { position, opts } });
  };
}

export function putQuestoes(questoes) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_QUESTOES", payload: questoes });
  };
}

/** */
export function setVisibilidade(visibilidade) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_VISIBILIDADE", payload: visibilidade });
  };
}

export function setAtivo(ativo) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_ATIVO", payload: ativo });
  };
}

export function setMostrarResultado(mostrarResultado) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_MOSTRARRESULTADO", payload: mostrarResultado });
  };
}

export function setMostrarGabarito(mostrarGabarito) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_MOSTRARGABARITO", payload: mostrarGabarito });
  };
}

export function resetQuiz() {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_RESET", payload: true });
  };
}

export function setEmailNovo(emailNovo) {
  return (dispatch) => {
    dispatch({ type: "SET_QUIZ_EMAILNOVO", payload: emailNovo });
  };
}

export function updateEmailsNovos(emailsNovos) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_QUIZ_EMAILSNOVOS", payload: emailsNovos });
  };
}

export function updateEmailsAdicionados(emailsAdicionados) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_QUIZ_EMAILSADICIONADOS",
      payload: emailsAdicionados,
    });
  };
}

export function initEditQuiz(quiz) {
  return (dispatch) => {
    dispatch({ type: "INIT_QUIZ_EDIT", payload: quiz });
  };
}
