import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  .sharesButton {
    margin-right: 8px;
    outline: none;
    border: none !important;
    .sharesButtonIcon,
    .sharesButtonIcon:hover,
    .sharesButtonIcon:active,
    .sharesButtonIcon:focus {
      outline: none;
      border: none !important;
    }
  }

  .required {
    position: relative;
    &:before {
      content: "*";
      right: -8px;
      color: red;
      position: absolute;
    }
  }
`;

export const ContainerLoading = styled.div`
  padding: 48px 0;
  text-align: center;
`;

export const Field = styled.div`
  margin-bottom: 32px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const Questao = styled.div`
  position: relative;
  padding: 0px 0px 48px 0px;
  margin-bottom: 16px;
  border: solid 1px #d9d9d9;
  [class="menu"] {
    position: absolute;
    margin-bottom: 0px;
    text-align: right;
    right: 8px;
    bottom: 16px;
  }
  .numero-questao {
    color: var(--primary2);
    font-weight: bold;
    position: absolute;
    top: 4px;
    left: 4px;
  }
`;

export const Enunciado = styled.div`
  font-size: 18px;
  color: var(--primary);
  text-transform: capitalize;
  background-color: var(--light-gray);
  padding: 48px 64px 16px;
`;

export const ContainerRespostas = styled.div``;

export const Resposta = styled.div`
  position: relative;
  padding-left: 48px;
  margin: 24px 16px;
  color: var(--primary);

  :before {
    left: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    text-align: center;
    border: solid 1px var(--primary2);
    border-radius: 50%;
    background-color: var(--primary2);
    font-weight: bold;
    color: white;
  }

  :nth-of-type(1):before {
    content: "A";
  }
  :nth-of-type(2):before {
    content: "B";
  }
  :nth-of-type(3):before {
    content: "C";
  }
  :nth-of-type(4):before {
    content: "D";
  }
  :nth-of-type(5):before {
    content: "E";
  }
`;

export const CertoErrado = styled.div`
  margin: 8px 56px;
  color: var(--primary);
  .resposta {
    display: inline-block;
    font-weight: bold;
    margin: 0 8px;
    font-size: 1.2em;
  }
`;

export const InvalidFeedback = styled.p`
  color: #ff4d4f;
`;
