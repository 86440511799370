import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";

import BannerBar from "../../../components/Bannerbar";
import { Container, LegislacaoText } from "./styles";
import { API_URL } from "../../../configs/config";

function LegislacaoFederalText({ match }) {
  const { url } = match.params;

  const [legislacaoFederal, setLegislacaoFederal] = useState([]);

  useEffect(() => {
    async function getLegislacao() {
      var s = [];
      async function mountSuperiores(data, id) {
        if (data.superiores) {
          await mountSuperiores(data.superiores, id);
        }
        if (data.id === parseInt(id, 10)) {
          data.foco = true;
        }
        return s.push(data);
      }

      async function mountInferiores(data, id) {
        data.map(async (d) => {
          d.foco = true;
          s.push(d);
          if (d.inferiores) {
            await mountInferiores(d.inferiores, id);
          }
          return true;
        });
        return true;
      }

      const _ = await fetch(`${API_URL}/legislacao/federal/${url}`, {
        method: "get",
      });
      const legislacaoResponse = await _.json();

      console.log(legislacaoResponse);

      if (legislacaoResponse.data.length > 0) {
        const id = legislacaoResponse.data[0].id;
        await mountSuperiores(legislacaoResponse.data[0], id);
        await mountInferiores(legislacaoResponse.data[0].inferiores, id);
        setLegislacaoFederal(s);
      }
    }
    getLegislacao();
  }, [url]);

  console.log(legislacaoFederal);

  return (
    <>
      <BannerBar />
      <Container className="container mt-5 mb-5">
        <Helmet>
          <title>{`Legislação`}</title>
          <meta name="description" content={`Legislação`}></meta>
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <LegislacaoText>
          <div className="text-center">
            <h3 className="header-title">
              CONSTITUIÇÃO DA REPÚBLICA FEDERATIVA DO BRASIL DE 1988
            </h3>
          </div>
          {legislacaoFederal &&
            legislacaoFederal.map((el, key) => {
              return (
                <p
                  className={[el.tipo, el.foco ? " foco" : ""].join(" ")}
                  key={"t" + key}
                >
                  <span className="num">{el.titulo} </span>
                  {el.texto}
                </p>
              );
            })}
        </LegislacaoText>
      </Container>
    </>
  );
}

export default withRouter(LegislacaoFederalText);
