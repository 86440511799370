const INITIAL_STATE = {
  cargo: null,
  cargos: null,
  loadCargos: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  /** GET */
    case 'GET_CARGO_REQUEST':
    case 'GET_CARGOS_REQUEST': {
      return { ...state, loadCargos: true }
    }
    case 'GET_CARGO_SUCCESS': {
      return { ...state, loadCargos: false, cargo: action.payload }
    }
    case 'GET_CARGOS_SUCCESS': {
      return { ...state, loadCargos: false, cargos: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_CARGO_FAILURE':
    case 'GET_CARGOS_FAILURE': {
      return { ...INITIAL_STATE, loadCargos: false }
    }

    /** STORE */
    case 'STORE_CARGO_REQUEST': {
      return { ...state, loadCargos: true }
    }
    case 'STORE_CARGO_SUCCESS': {
      return { ...state, loadCargos: false}
    }
    case 'STORE_CARGO_FAILURE': {
      return { ...state, loadCargos: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}