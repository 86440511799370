import { API_URL } from "../configs/config";

export const getConcurso = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_CONCURSO_REQUEST" });
    fetch(`${API_URL}/concurso/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_CONCURSO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_CONCURSO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getConcursos = () => {
  return (dispatch) => {
    dispatch({ type: "GET_CONCURSOS_REQUEST" });
    fetch(`${API_URL}/concurso`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_CONCURSOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_CONCURSOS_FAILURE",
          payload: error,
        })
      );
  };
};

export const getAnos = () => {
  return (dispatch) => {
    dispatch({ type: "GET_ANOS_REQUEST" });
    fetch(`${API_URL}/concurso/anos`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_ANOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_ANOS_FAILURE",
          payload: error,
        })
      );
  };
};
