import { API_URL } from "../configs/config";

export const getBanca = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_BANCA_REQUEST" });
    fetch(`${API_URL}/banca/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_BANCA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_BANCA_FAILURE",
          payload: error,
        })
      );
  };
};

export const getBancas = () => {
  return (dispatch) => {
    dispatch({ type: "GET_BANCAS_REQUEST" });
    fetch(`${API_URL}/banca`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_BANCAS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_BANCAS_FAILURE",
          payload: error,
        })
      );
  };
};
