const INITIAL_STATE = {
  banca: null,
  bancas: null,
  loadBancas: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  /** GET */
    case 'GET_BANCA_REQUEST':
    case 'GET_BANCAS_REQUEST': {
      return { ...state, loadBancas: true }
    }
    case 'GET_BANCA_SUCCESS': {
      return { ...state, loadBancas: false, banca: action.payload }
    }
    case 'GET_BANCAS_SUCCESS': {
      return { ...state, loadBancas: false, bancas: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_BANCA_FAILURE':
    case 'GET_BANCAS_FAILURE': {
      return { ...INITIAL_STATE, loadBancas: false }
    }

    /** STORE */
    case 'STORE_BANCA_REQUEST': {
      return { ...state, loadBancas: true }
    }
    case 'STORE_BANCA_SUCCESS': {
      return { ...state, loadBancas: false}
    }
    case 'STORE_BANCA_FAILURE': {
      return { ...state, loadBancas: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}