import React, { Component } from 'react'
import style from './style.module.css'
import { Row, Avatar, Col, Skeleton, Empty, Form, Alert } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { bindActionCreators } from 'redux'
import { getUsuario } from '../../actions/Usuario.action'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import moment from 'moment'

const COLORS = ['#52c41a', '#ff4d4f'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, name
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};


class UsuarioEstatistica extends Component {

  componentDidMount = () => {
    const { accessToken, usuario } = this.props.login
    if (usuario && accessToken) {
      this.props.getUsuario(usuario.id, accessToken)
    }
  }

  renderLineChart(date) {
    var dataTemp = []
    var data = []
    var min = null
    var max = null
    
    Object.keys(date).forEach(e => {
      if (e < min || min === null) { min = e }
      if (e > max || max === null) { max = e }
      dataTemp.push(
        {
          name: e,
          'questões respondidas': date[e]
        }
      )
    })

    var current = min
    while (current < max) {
      current = moment(current, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY');
      let cur = current
      var a = dataTemp.find((d) => d.name === cur)
      if (!a) {
        data.push(
          {
            name: current,
            'questões respondidas': 0
          }
        )
      } else {
        data.push(a)
      }
    }

    return (
      <ResponsiveContainer height={320} width='100%'>
        <LineChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="questões respondidas" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  renderChart = (acertos, erros) => {

    const data = [
      { name: 'Acertos', value: acertos },
      { name: 'Erros', value: erros },
    ];

    return (
      <PieChart width={140} height={140}>
        <Pie
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    );
  }

  render() {
    const { loadUsuarios, usuario } = this.props.usuarios
    var questaoGrouped = []
    var acertos = 0
    var erros = 0
    var porDia = []
    if (usuario) {
      var usuarios_questoes = []
      usuario.usuarios_questoes.map(uq => {
        uq.data = moment(uq.created_at).format('DD/MM/YYYY')
        usuarios_questoes.push(uq)//moment(uq.update_at).format('DD/MM/YYYY')
        return true
      })
      questaoGrouped = _.uniqBy(usuarios_questoes, 'idQuestao');
      questaoGrouped.map(q => {
        if (q.acerto === 'true') {
          acertos++
        } else {
          erros++
        }
        if (porDia[q.data]) {
          porDia[q.data] = porDia[q.data] + 1
        } else {
          porDia[q.data] = 1;
        }
        return true
      })
    }

    return (
      <>
        {
          usuario
            ? (
              <>
                <Row gutter={[24, 48]}>
                  <Col flex="96px">
                    <Avatar size={72} icon={<UserOutlined />} />
                  </Col>
                </Row>

                <div className={style.form}>
                  <Form name="dados" onFinish={this.onFinish} initialValues={{}} labelCol={{ span: 12 }} wrapperCol={12}>
                    <h4 className="strong" style={{ fontSize: 24 }}>Acertos</h4>
                    <Alert style={{marginBottom: 24}} message="É levado em consideração a primeira vez a qual a questão foi respondida." type="info" />
                    <Row>
                      <Col span={8}>
                        <Form.Item label="Respondidas">
                          <span style={{ padding: '0 12px' }}>{questaoGrouped.length}</span>
                        </Form.Item>
                        <Form.Item label="Acertos">
                          <span style={{ padding: '0 12px' }}>{acertos}</span>
                        </Form.Item>
                        <Form.Item label="Erros">
                          <span style={{ padding: '0 12px' }}>{erros}</span>
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        {this.renderChart(acertos, erros)}
                      </Col>
                    </Row>
                  </Form>
                </div>

                <Row>
                  <Col span={24}>
                    <h4 className="strong" style={{ fontSize: 24 }}>Questões respondidas por dia</h4>
                  </Col>
                  <Col span={24}>
                    {
                      this.renderLineChart(porDia)
                    }
                  </Col>
                </Row>
              </>
            )
            : (
              !loadUsuarios
                ? <Empty description="Dados do Usuário não encontrados" style={{ margin: '64px 0 128px 0' }} />
                : <Skeleton active paragraph={{ rows: 20 }}></Skeleton>
            )
        }
      </>
    )
  }
}

const mapStateToProps = state =>
  ({
    login: state.login,
    usuarios: state.usuarios
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUsuario }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioEstatistica)