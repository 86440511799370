const INITIAL_STATE = {
  questao: null,
  count: null,
  curPage: null,
  questoes: null,
  loadQuestoes: false,
  total: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_QUESTAO_REQUEST':
    case 'GET_QUESTOES_REQUEST': {
      return { ...state, loadQuestoes: true }
    }
    case 'GET_QUESTAO_SUCCESS': {
      return { ...state, loadQuestoes: false, questao: action.payload }
    }
    case 'GET_QUESTOES_SUCCESS': {
      return { ...state, loadQuestoes: false, questoes: action.payload.data, count: action.payload.count, total: action.payload.total, curPage: action.payload.curPage }
    }
    case 'GET_QUESTAO_FAILURE':
    case 'GET_QUESTOES_FAILURE': {
      return { ...INITIAL_STATE, loadQuestoes: false }
    }

    /** STORE */
    case 'STORE_QUESTAO_REQUEST': {
      return { ...state, loadQuestoes: true }
    }
    case 'STORE_QUESTAO_SUCCESS': {
      return { ...state, loadQuestoes: false }
    }
    case 'STORE_QUESTAO_FAILURE': {
      return { ...state, loadQuestoes: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}