import React from "react";
import logo from "../../images/logo2.webp";
import logoWhite from "../../images/logo3.webp";
import logoJpg from "../../images/logo2jpg.jp2";
import logoWhiteJpg from "../../images/logo3jpg.jp2";
import { CadeDireitoLogo } from "./styles";

export default (props) => {
  const type = props.type || "";
  return (
    <CadeDireitoLogo style={{ marginRight: 8 }} className={type} to={`/`}>
      <picture>
        <source
          srcSet={type === "white" ? logoWhite : logo}
          type="image/webp"
        />
        <img
          width={198}
          height={33}
          alt="Cade Direito"
          src={type === "white" ? logoWhiteJpg : logoJpg}
        />
      </picture>
    </CadeDireitoLogo>
  );
};
