import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";

import BannerBar from "../../../components/Bannerbar";

import BarraPesquisa from "./Components/BarraPesquisa";
import Resultados from "./Components/Resultados";

import { Container } from "./styles";

function LegislacaoFederalSearch() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resultado, setResultado] = useState(null);

  global.resultado = resultado;

  return (
    <>
      <BannerBar />
      <BarraPesquisa
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        setResultado={setResultado}
      />
      <Container className="container mt-5 mb-5">
        <Helmet>
          <title>{`Legislação`}</title>
          <meta name="description" content={`Legislação`}></meta>
          <meta name="robots" content="index, follow"></meta>
        </Helmet>

        {loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {error && <p>Erro!</p>}
            {resultado === undefined && (
              <p>Não foi encontrado nenhum resultado.</p>
            )}
            {resultado && resultado.length > 0 && (
              <Resultados resultados={resultado} />
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default withRouter(LegislacaoFederalSearch);
