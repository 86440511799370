const INITIAL_STATE = {
  comentario: null,
  comentarios: null,
  loadComentarios: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  /** GET */
    case 'GET_COMENTARIO_REQUEST':
    case 'GET_COMENTARIOS_REQUEST': {
      return { ...state, loadComentarios: true }
    }
    case 'GET_COMENTARIO_SUCCESS': {
      return { ...state, loadComentarios: false, comentario: action.payload }
    }
    case 'GET_COMENTARIOS_SUCCESS': {
      return { ...state, loadComentarios: false, comentarios: action.payload, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_COMENTARIO_FAILURE':
    case 'GET_COMENTARIOS_FAILURE': {
      return { ...INITIAL_STATE, loadComentarios: false }
    }

    /** STORE */
    case 'STORE_COMENTARIO_REQUEST': {
      return { ...state, loadComentarios: true }
    }
    case 'STORE_COMENTARIO_SUCCESS': {
      return { ...state, loadComentarios: false}
    }
    case 'STORE_COMENTARIO_FAILURE': {
      return { ...state, loadComentarios: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}