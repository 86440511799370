import React, { Component } from "react";
import { Tabs } from "antd";
import {
  faComments,
  faExclamationTriangle,
  faChartBar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Comentario from "../Comentario";
import Estatisticas from "../Estatisticas";
import ReportarErro from "../ReportarErro";

const { TabPane } = Tabs;

class MenuQuestao extends Component {
  render() {
    const { comentarios, idQuestao, tipoQuestao } = this.props;

    return (
      <Tabs defaultActiveKey="5">
        <TabPane
          tab={
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 8 }}
                icon={faComments}
              ></FontAwesomeIcon>
              Comentários
            </span>
          }
          key="1"
        >
          {comentarios && comentarios.length > 0
            ? comentarios.map((c) => (
                <Comentario
                  login={this.props.login}
                  key={c.id}
                  comentario={c}
                ></Comentario>
              ))
            : []}
          {this.props.renderComentar()}
        </TabPane>

        <TabPane
          tab={
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 8 }}
                icon={faChartBar}
              ></FontAwesomeIcon>
              Estatísticas
            </span>
          }
          key="2"
        >
          <Estatisticas
            idQuestao={idQuestao}
            tipoQuestao={tipoQuestao}
          ></Estatisticas>
        </TabPane>

        {/*<TabPane
          tab={
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 8 }}
                icon={faBookReader}
              ></FontAwesomeIcon>
              Adicionar ao simulado
            </span>
          }
          key="3"
        >
          Adicionar ao simulado
        </TabPane>*/}

        <TabPane
          tab={
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 8 }}
                icon={faExclamationTriangle}
              ></FontAwesomeIcon>
              Reportar erro
            </span>
          }
          key="4"
        >
          <ReportarErro idQuestao={idQuestao} />
        </TabPane>
        <TabPane tab="" key="5"></TabPane>
      </Tabs>
    );
  }
}

export default MenuQuestao;
