import acre from './acre.png'
import alagoas from './alagoas.png'
import amapa from './amapa.png'
import amazonas from './amazonas.png'
import bahia from './bahia.png'
import ceara from './ceara.png'
import distritofederal from './distritofederal.png'
import espiritosanto from './espiritosanto.png'
import goias from './goias.png'
import maranhao from './maranhao.jpg'
import matogrosso from './matogrosso.png'
import matogrossosul from './matogrossosul.png'
import minasgerais from './minasgerais.png'
import para from './para.png'
import paraiba from './paraiba.png'
import parana from './parana.png'
import pernambuco from './pernambuco.png'
import piaui from './piaui.png'
import riodejaneiro from './riodejaneiro.png'
import riograndedonorte from './riograndedonorte.png'
import riograndedosul from './riograndedosul.png'
import rondonia from './rondonia.png'
import roraima from './roraima.png'
import santacatarina from './santacatarina.png'
import saopaulo from './saopaulo.png'
import sergipe from './sergipe.png'
import tocantins from './tocantins.png'
import brasil from './brasil.png'

const UF = {
  'AC': acre,
  'AL': alagoas,
  'AP': amapa,
  'AM': amazonas,
  'BA': bahia,
  'CE': ceara,
  'DF': distritofederal,
  'ES': espiritosanto,
  'GO': goias,
  'MA': maranhao,
  'MT': matogrosso,
  'MS': matogrossosul,
  'MG': minasgerais,
  'PA': para,
  'PB': paraiba,
  'PR': parana,
  'PE': pernambuco,
  'PI': piaui,
  'RJ': riodejaneiro,
  'RN': riograndedonorte,
  'RS': riograndedosul,
  'RO': rondonia,
  'RR': roraima,
  'SC': santacatarina,
  'SP': saopaulo,
  'SE': sergipe,
  'TO': tocantins,
  'NACIONAL': brasil
}

export {
  acre,
  alagoas,
  amapa,
  amazonas,
  bahia,
  ceara,
  distritofederal,
  espiritosanto,
  goias,
  maranhao,
  matogrosso,
  matogrossosul,
  minasgerais,
  para,
  paraiba,
  parana,
  pernambuco,
  piaui,
  riodejaneiro,
  riograndedonorte,
  riograndedosul,
  rondonia,
  roraima,
  santacatarina,
  saopaulo,
  sergipe,
  tocantins,
  brasil,
  UF
}