import React from "react";
import { Helmet } from "react-helmet";

export default function RenderHelmet({ title, description, keywords }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description}></meta>}
      {keywords && <meta name="keywords" content={keywords}></meta>}
      <meta name="robots" content="index, follow"></meta>
    </Helmet>
  );
}
