import React, { Component } from "react";
import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Button,
  Divider,
  Result,
  Select,
} from "antd";

import {
  QuestionCircleOutlined,
  FacebookFilled,
  MailTwoTone,
} from "@ant-design/icons";
import style from "./style.module.css";
import TermosDeUso from "../../components/TermosDeUso";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_ID } from "../../configs/config";
import FacebookLogin from "react-facebook-login";
import config from "../../config.json";
import mail001 from "../../images/mail001.jpg";
import { Helmet } from "react-helmet";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

const { Option } = Select;
class Registrar extends Component {
  state = {
    facebookParams: [],
    accessToken: "",
    loading: false,
    status: null,
    mensagem: [],
    facebookError: false,
    registerError: false,
  };

  recaptcha = null;
  values = [];

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/registrar");
    window.scrollTo(0, 0);
  };

  setAccessToken = () => {
    const accessToken = "";
    this.setState({ accessToken: accessToken });
  };

  onResolved = () => {
    const opts = {
      usuario: this.values.usuario,
      email: this.values.email,
      senha: this.values.senha,
      nome: this.values.nome,
      perfil: this.values.perfil,
      recaptchaKey: this.recaptcha.getResponse(),
    };
    this.callbackRegistrar(opts);
  };

  onFinish = (values) => {
    this.values = values;
    this.recaptcha.execute();
  };

  renderSucesso() {
    if (this.state.status === "sucesso") {
      return (
        <Result
          icon={<MailTwoTone />}
          style={{ margin: "96px 0" }}
          title="Conta registrada com sucesso!"
          subTitle="Uma mensagem de ativação foi enviada para o e-mail cadastrado, acesse-o para começar a usar."
        />
      );
    }
    if (this.state.status === "sucessoFacebook") {
      return (
        <Result
          status="success"
          style={{ margin: "96px 0" }}
          title="Conta registrada com sucesso!"
          subTitle={
            <>
              Vamos lá, faça o login com o{" "}
              <FacebookFilled style={{ color: "var(--facebook)" }} /> Facebook
              comece a usar.
            </>
          }
        />
      );
    }
  }

  disabilitarErro = () => {
    this.setState({ facebookError: false, registerError: false });
  };

  renderHeader = () => {
    return (
      <Helmet>
        <title>{`Cadê Direito`}</title>
        <meta
          name="description"
          content={`Questões de concurso, questões da área de direito.`}
        ></meta>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
    );
  };

  isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  };

  render() {
    if (this.state.facebookError || this.state.registerError) {
      setTimeout(() => {
        this.disabilitarErro();
      }, 6000);
    }

    return (
      <>
        {this.renderHeader()}
        <div style={{ margin: "48px auto" }} className="container">
          {this.state.status !== "sucesso" &&
          this.state.status !== "sucessoFacebook" ? (
            <Row>
              <Col sm={{ span: 24 }} md={{ span: 12 }}>
                <div style={{ textAlign: "center", padding: "8px 48px" }}>
                  <h1 style={{ color: "var(--primary)" }}>Crie a sua conta</h1>
                  <div className={style.textoLateral}>
                    <p style={{ fontSize: "1.2em", color: "#809fbc" }}>
                      Preencha o formulário ao lado com os seus dados de acesso.
                    </p>
                    <p style={{ fontSize: "1.2em", color: "#809fbc" }}>
                      Após a confirmação, uma mensagem de ativação será enviada
                      para o e-mail cadastrado.
                    </p>
                  </div>
                  <img
                    alt="e-mail"
                    style={{ width: "100%" }}
                    src={mail001}
                  ></img>
                </div>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                className={style.formContainer}
              >
                <Form
                  className={style.formRegistrar}
                  name="register"
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <span>
                    Usuário{" "}
                    <Tooltip title="Como gostaria de ser chamado?">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                  <Form.Item
                    name="usuario"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu nome de usuário!",
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: "O nome deve possui 5 caracteres ou mais!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Usuário" />
                  </Form.Item>
                  <span>Nome</span>
                  <Form.Item
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu nome completo!",
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: "O nome deve possui 5 caracteres ou mais!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Nome completo" />
                  </Form.Item>
                  <span>E-mail</span>
                  <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        type: "email",
                        message: "O E-mail informado não é válido!",
                      },
                      {
                        required: true,
                        message: "Por favor, informe o seu E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="Informe o e-mail" />
                  </Form.Item>
                  <span>
                    Perfil{" "}
                    <Tooltip title="Qual o perfil?">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                  <Form.Item>
                    <Select
                      allowClear
                      name="perfil"
                      value={this.state.tipo}
                      placeholder="Selecione um perfil"
                    >
                      <Option value={1} key={1}>
                        Estudante
                      </Option>
                      <Option value={2} key={2}>
                        Professor
                      </Option>
                      <Option value={3} key={3}>
                        Profissional de Direito
                      </Option>
                    </Select>
                  </Form.Item>
                  <span>Senha</span>
                  <Form.Item
                    name="senha"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe a sua senha!",
                      },
                      {
                        min: 5,
                        message: "A senha deve possui 5 caracteres ou mais!",
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Crie um senha" />
                  </Form.Item>
                  <span>Confirmar senha</span>
                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Por favor, confirme sua senha!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("senha") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "As duas senhas precisam ser iguais!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirme a senha" />
                  </Form.Item>
                  <Recaptcha
                    ref={(ref) => (this.recaptcha = ref)}
                    sitekey={RECAPTCHA_ID}
                    onResolved={this.onResolved}
                  />
                  <Form.Item
                    name="termos"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                "Precisa aceitar os termos de uso."
                              ),
                      },
                    ]}
                  >
                    <Checkbox>
                      Eu li os <TermosDeUso>Termos de uso</TermosDeUso>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    help={
                      this.state.registerError
                        ? "Conta já cadastrada ou inválida."
                        : null
                    }
                    validateStatus={this.state.registerError ? "error" : ""}
                  >
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      className={style.buttonRegistrar}
                      htmlType="submit"
                      size="large"
                      disabled={this.state.loading}
                    >
                      {!this.state.loading ? "Registrar" : "Registrando..."}
                    </Button>
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Divider>Ou cadastre-se com</Divider>
                  </Form.Item>
                  <Form.Item
                    help={
                      this.state.facebookError
                        ? "Conta já cadastrada ou inválida."
                        : null
                    }
                    validateStatus={this.state.facebookError ? "error" : ""}
                  >
                    <Row gutter={8}>
                      <Col offset={6} span={12}>
                        <FacebookLogin
                          isMobile={this.isFacebookApp()}
                          disableMobileRedirect={!this.isFacebookApp()}
                          autoLoad={this.isFacebookApp()}
                          redirectUri={window.location.origin + "/login"}
                          isDisabled={this.state.loading}
                          appId={config.FACEBOOK_APP_ID}
                          fields="name,email,picture"
                          textButton=" Facebook"
                          cssClass={
                            "ant-btn ant-btn-facebook ant-btn-lg ant-btn-100"
                          }
                          style={{ width: "100%" }}
                          icon={<FacebookFilled />}
                          callback={(callback) =>
                            this.callbackFacebook(callback)
                          }
                        />
                      </Col>
                      {/*<Col span={12}>
                        <Button type="google" size="large" icon={<GoogleSquareFilled />} className='ant-btn-100' disabled={this.state.loading}>Google</Button>
                      </Col>*/}
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          ) : (
            this.renderSucesso()
          )}
        </div>
      </>
    );
  }

  callbackRegistrar = (opts) => {
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    this.setState({ loading: true });
    fetch(`https://api.cadedireito.com.br/v1/usuario/register`, {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r === "sucesso") {
          this.setState({ loading: false, status: "sucesso" });
        } else {
          this.setState({
            loading: false,
            status: "erro",
            registerError: true,
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro", registerError: true });
      });
  };

  callbackFacebook = (response) => {
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(
      { access_token: response.accessToken },
      null,
      2
    );
    this.setState({ loading: true });
    fetch("https://api.cadedireito.com.br/v1/registerWithFacebook", {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r;
      })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r === "sucesso") {
          this.setState({ loading: false, status: "sucessoFacebook" });
        } else {
          throw Error(r);
        }
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro", facebookError: true });
      });
  };
}

export default Registrar;
