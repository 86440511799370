import React from "react";
import { Menu, Divider } from "antd";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";

const { SubMenu } = Menu;

export default function MenuBar({ location }) {
  const { acao, id } = useSelector((state) => {
    return state.quiz;
  });

  return (
    <Menu
      className={style.menuBar}
      selectedKeys={[location ? location.pathname : null]}
      mode="horizontal"
    >
      <SubMenu key="legislacao" title="Legislacao">
        <Menu.ItemGroup title="Federal">
          <Menu.Item key="setting:0">
            <Link to={`/legislacao/federal`}>Constituição</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Divider style={{ marginTop: 12, marginBottom: 0 }}></Divider>
        <Menu.Item key="setting:0">
          <Link to={`/legislacao/federal/pesquisar`}>Pesquisar</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="/quiz" title="Quiz">
        <Menu.Item key="setting:1">
          <Link to={`/quiz/listar`}>Lista de Quiz</Link>
        </Menu.Item>
        <Menu.Item key="setting:2">
          <Link
            to={{
              pathname: `/quiz/novo`,
              query: { novo: true, criandoExists: acao },
            }}
          >
            Novo Quiz
          </Link>
        </Menu.Item>
        {acao === "criação" && (
          <Menu.Item key="setting:3">
            <Link
              to={{
                pathname: `/quiz/novo`,
                query: { novo: false },
              }}
            >
              Continuar criando o quiz
            </Link>
          </Menu.Item>
        )}
        {acao === "edição" && (
          <Menu.Item key="setting:3">
            <Link
              to={{
                pathname: `/quiz/editar/${id}`,
                query: { novo: false },
              }}
            >
              Continuar editando o quiz
            </Link>
          </Menu.Item>
        )}
      </SubMenu>

      <Menu.Item key="/questoes">
        <Link to={`/questoes`}>Questões</Link>
      </Menu.Item>

      <SubMenu key="/menuconcursos" title="Concursos">
        <Menu.Item key="/concursos">
          <Link to={`/concursos`}>Provas</Link>
        </Menu.Item>

        <Menu.Item key="/cargos">
          <Link to={`/cargos`}>Cargos</Link>
        </Menu.Item>

        <Menu.Item key="/bancas">
          <Link to={`/bancas`}>Bancas</Link>
        </Menu.Item>

        <Menu.Item key="/orgaos">
          <Link to={`/orgaos`}>Órgãos</Link>
        </Menu.Item>

        <Menu.Item key="/disciplinas">
          <Link to={`/disciplinas`}>Disciplinas</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="/grupo-de-estudos">
        <Link to={`/grupo-de-estudos`}>Grupo de estudos</Link>
      </Menu.Item>

      <Menu.Item key="/instagram">
        <a
          className={style.socialIconLink}
          target="_BLANK"
          rel="noopener noreferrer"
          aria-label="Instagram"
          href="https://www.instagram.com/cade.direito"
        >
          <FontAwesomeIcon icon={faInstagram} className={style.socialIcon} />
        </a>
      </Menu.Item>

      <Menu.Item key="/telegram">
        <a
          className={style.socialIconLink}
          target="_BLANK"
          rel="noopener noreferrer"
          aria-label="Telegram"
          href="https://t.me/cadedireito"
        >
          <FontAwesomeIcon icon={faTelegram} className={style.socialIcon} />
        </a>
      </Menu.Item>
    </Menu>
  );
}
