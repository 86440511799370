import React, { Component } from "react";
import { Skeleton, Result } from "antd";
import RenderHelmet from "../../components/RenderHelmet";
import { withRouter } from "react-router";

class App extends Component {
  state = {
    status: "error",
    loading: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const search = this.props.location.search;
    let { k } = (
      search.match(new RegExp("([^?=&]+)(=([^&]*))?", "g")) || []
    ).reduce(function (result, each, n, every) {
      let [key, value] = each.split("=");
      result[key] = value;
      return result;
    }, {});
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify({ k: k });
    fetch("https://api.cadedireito.com.br/v1/usuario/activate", {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        if (r.status !== 200) {
          throw Error(r.statusText);
        }
        return r;
      })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        this.setState({ loading: false, status: "sucesso" });
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro" });
      });
  }

  renderSucesso() {
    return (
      <Result
        status="success"
        style={{ margin: "96px 0" }}
        title="Conta ativada com sucesso!"
        subTitle="Vamos lá, faça o login e comece a usar."
      />
    );
  }

  renderErro() {
    return (
      <Result
        status="error"
        style={{ margin: "96px 0" }}
        title="Erro!"
        subTitle="Não foi possível utilizar a chave para ativar a conta."
      />
    );
  }

  render() {
    return (
      <>
        <div className="container container-xs">
          <RenderHelmet title={"Cade Direito: Ativar cadastro"} />
          {!this.state.loading ? (
            this.state.status === "sucesso" ? (
              this.renderSucesso()
            ) : (
              this.renderErro()
            )
          ) : (
            <Skeleton active paragraph={{ rows: 2 }}></Skeleton>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(App);
