import React, { Component } from "react";
import style from "./style.module.css";
import { Form, Input, Button, Col, Row, Result } from "antd";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_ID } from "../../configs/config";
import { withRouter } from "react-router";
import RenderHelmet from "../../components/RenderHelmet";

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

class NovaSenha extends Component {
  state = {
    loading: false,
    token: null,
    status: null,
  };

  componentDidMount() {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/novasenha");
    window.scrollTo(0, 0);
    const search = this.props.location.search;
    let { k } = (
      search.match(new RegExp("([^?=&]+)(=([^&]*))?", "g")) || []
    ).reduce(function (result, each, n, every) {
      let [key, value] = each.split("=");
      result[key] = value;
      return result;
    }, {});
    this.setState({ token: k });
  }

  recaptcha = null;
  values = [];

  onFinish = (values) => {
    this.values = values;
    this.recaptcha.execute();
  };

  onResolved = () => {
    const { token } = this.state;
    const opts = {
      senha: this.values.senha,
      token,
      recaptchaKey: this.recaptcha.getResponse(),
    };
    this.callbackNovaSenha(opts);
  };

  callbackNovaSenha = (opts) => {
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    this.setState({ loading: true });
    fetch(`https://api.cadedireito.com.br/v1/novasenha`, {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r.status === "sucesso") {
          this.setState({ loading: false, status: "sucesso" });
        } else {
          this.setState({ loading: false, status: "erro" });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro" });
      });
  };

  renderSucesso() {
    return (
      <Result
        status="success"
        style={{ margin: "96px 0" }}
        title="Nova senha criada com sucesso!"
        subTitle="Vamos lá, faça o login com sua nova senha."
      />
    );
  }

  renderErro() {
    return (
      <Result
        status="error"
        style={{ margin: "96px 0" }}
        title="Erro!"
        subTitle="Não foi possível utilizar a chave para alterar a senha."
      />
    );
  }

  render() {
    const { status } = this.state;

    if (status === "sucesso") {
      return this.renderSucesso();
    }

    if (status === "erro") {
      return this.renderErro();
    }

    return (
      <>
        <div style={{ margin: "48px auto" }} className="container">
          <RenderHelmet title={"Cade Direito: Alterar senha"} />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div style={{ textAlign: "center", padding: "8px 48px" }}>
                <h1>Nova senha</h1>
                <p>Crie uma nova senha.</p>
              </div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} style={{ width: "100%" }}>
              <Form
                className={style.formRecuperar}
                name="recuperacao"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <span>Senha</span>
                <Form.Item
                  name="senha"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, informe a sua senha!",
                    },
                    {
                      min: 5,
                      message: "A senha deve possui 5 caracteres ou mais!",
                      whitespace: true,
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Crie um senha" />
                </Form.Item>
                <span>Confirmar senha</span>
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Por favor, confirme sua senha!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("senha") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "As duas senhas precisam ser iguais!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirme a senha" />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={this.state.loading}
                  >
                    {!this.state.loading ? "Cadastrar" : "Cadastrando..."}
                  </Button>
                </Form.Item>

                <Recaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={RECAPTCHA_ID}
                  onResolved={this.onResolved}
                />
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(NovaSenha);
