import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { logar, logarComFacebook, resetReducers } from '../../../actions/Login.action'
import { Form, Input, Button, Divider } from 'antd'
import { UserOutlined, LockOutlined, FacebookFilled } from '@ant-design/icons';
import style from './style.module.css'
import FacebookLogin from 'react-facebook-login'
import config from '../../../config.json'
import { Link } from 'react-router-dom'

class Login extends Component {

  onFinish = values => {
    this.props.logar(values.username, values.password)
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  };

  callbackFacebook = (response) => {
    this.props.logarComFacebook(response.accessToken)
  }

  isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  }

  render() {
    const { isAuthenticate, loadLogin, isFailure } = this.props.login

    if (isAuthenticate) {
      return false
    }

    if (isFailure) {
      //this.formRef.current.resetFields();
      setTimeout(() => {
        this.props.resetReducers()
      }, 2000)
    }

    return(
      <Form
        name="basic"
        ref={this.formRef}
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item name="username" rules={[{ required: true, message: 'Informe o e-mail ou usuário!' }]}>
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail ou usuário" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true, message: 'Informe a senha!' }]}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Senha" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={style.btnSubmit} disabled={loadLogin}>
            Entrar
          </Button>
        </Form.Item>

        {
          isFailure ? (
            <div>
              <p className={style.loginFailure}>Login ou senha inválido(s)</p>
            </div>
          ) : (
              []
          )
        }

        {/*<Form.Item name="remember" valuePropName="checked" style={{marginBottom: 0}}>
          <Checkbox>Lembre-me</Checkbox>
          </Form.Item>*/}

        <div style={{textAlign: 'center'}}><Link to={`/recuperar`}>Esqueci minha senha</Link></div>
        
        <Divider orientation="center">ou entre com</Divider>

        <Form.Item>
          {/*<Button type="facebook" icon={<FacebookFilled />}>Facebook</Button>*/}
          <FacebookLogin
            isMobile={this.isFacebookApp()}
            disableMobileRedirect={!this.isFacebookApp()}
            autoLoad={this.isFacebookApp()}
            redirectUri={window.location.origin + "/login"}
            isDisabled={loadLogin}
            appId={config.FACEBOOK_APP_ID}
            fields="name,email,picture"
            textButton=" Facebook"
            cssClass={"ant-btn ant-btn-100 ant-btn-facebook"}
            style={{ width: '100%' }}
            icon={<FacebookFilled />}
            callback={callback => this.callbackFacebook(callback)}
          />
          {/*<Button type="google" icon={<GoogleSquareFilled />} style={{marginLeft: 10}} disabled={loadLogin}>Google</Button>*/}
        </Form.Item>
      </Form>
    )
  }
}

const mapStateToProps = state =>
  ({
    login: state.login
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logar, logarComFacebook, resetReducers }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)